<!--
 * @Author: liuxiang
 * @Date: 2021-01-22 13:48:40
 * @LastEditors: lcl
 * @LastEditTime: 2022-01-10 11:18:01
 * @Description: 后台系统登录
-->

<template>
  <div v-loading="loading" class="page">
    <div class="name">
      掌站·营销宝
    </div>
     <div class="login-panel">
      您身边的私域营销宝
      <img
        src="//oss.zzkj8888.com/images/20210128/437abcb3bb754b7993cb77f425ad88fc.png"
        alt="企业微信登录"
        @click="login"
      />
    </div>
  </div>
</template>

<script>
  import { mapMutations } from "vuex"
  import AuthApi from "@/api/authApi"
  import CustomerJourneyApi from '@/api/customerJourney/index'
  import CommonApi from "@/api/commonApi"
  import HomeLogin from '@/lib/HomeLogin.js'

  export default {
    data() {
      return {
        loading: false,
      };
    },
    computed: {
      authCode() {
        return this.$route.query['auth_code'] || ''
      },
      homeToken() {
        return this.$route.query['t_code'] || ''
      }
    },
    async created() {

      const local = location.href
      let reg = /^http:\/\/\localhost/g
      let isLocal = local.match(reg)

      // 官网
      if (this.homeToken) {
        this.homeLogin()
      } else if(this.$store.state.user.token) {
        this.SETMIDGLOBAL(0)
        this.$router.replace({path: "/dashboard"})
      } else if (isLocal&&isLocal.length > 0) { // 本地
        this.local()
      } else if (this.authCode) {
        this.scanLogin()
      } else {
        this.toHomeLogin()
      }

    },
    /*async created() {
      if (this.authCode) {
        // 清空用户信息
        this.setToken("");
        this.setUserInfo({});
        this.setMenuList([]);
        this.setAuthRoutes([]);
        sessionStorage.clear();
        this.checkUser(this.authCode);
      } else {
        // 如果已登录跳转至首页
        if(this.$store.state.user.token) {
          this.SETMIDGLOBAL(0)
          this.$router.replace({path: "/dashboard"})
        }
      }
    },*/
    mounted() {
      // 移除全局的弹窗
      let doms2 = document.getElementsByClassName("el-dialog__wrapper");
      let doms = document.getElementsByClassName("v-modal")
      doms2.length && doms2.forEach(dom=>{
        if(dom) dom.remove()
      })
      doms.length && doms.forEach(dom=>{
        if(dom) dom.remove()
      })
    },
    methods: {
      ...mapMutations("user", ["setToken", "setUserInfo", 'SETMIDGLOBAL', "setMenuList", "setAuthRoutes", "setCustomerJourneyAttr"]),
      async login() {
        this.loading = true;
        let rs = await AuthApi.createWechatAuthUrl();
        this.loading = false;
        if (rs.status === 200) {
          window.location.href = rs.payload;
        }
      },
      // 官网登录
      async homeLogin() {
          this.loading = true

          this.clearInfo()
          this.setToken(this.homeToken)

          // 获取用户信息
          const infoSuccess = await this.getUserInfo()

          if (infoSuccess) {
            // this.getLoginPropertyConfigType()
            this.SETMIDGLOBAL(0)
            this.$router.replace({path: "/dashboard"})
          }

      },
      toHomeLogin() {
        return window.location.replace(HomeLogin)
      },
      // 后台扫码登录
      scanLogin() {
          this.clearInfo()
          this.checkUser(this.authCode)
      },
      // 后台扫码获取登录信息
      async checkUser(key) {

        this.loading = true

        let rs = await AuthApi.checkLogin(key)

        if (rs.status === 200) {

          const payloadJson = JSON.parse(rs.payload)
          const token = payloadJson.token

          this.setToken(token)
          this.setUserInfo(payloadJson)

          this.getLoginPropertyConfigType()
        } else if (rs.status === 500) {

          this.$message.error(rs.error)

        } else if (rs.status === 10010) {

          this.$router.push({ path: '/jumpPrompt', query: { url: rs.payload } })

        }
      },
       // 客户旅程模块：获取当前账号设置的属性集 跳转首页
      async getLoginPropertyConfigType() {

          this.loading = true
          let attr = await CustomerJourneyApi.getLoginPropertyConfigType()
          this.loading = false

          if(attr.status===200) {
            this.setCustomerJourneyAttr(attr.payload)
            this.SETMIDGLOBAL(0)
            this.$router.replace({path: "/dashboard"})
          } else {
            this.$message.error(attr.error)
          }

      },
      async getUserInfo() {

        this.loading = true
        const res = await CommonApi.getUserInfo()
        this.loading = false

        let payload = res.payload || {}

        if(res.status === 200) {

          // 未绑定手机号
          if (!payload.generalUser) {

            if (payload.user) {
              let userInfo = JSON.parse(payload.user)
              this.setUserInfo(userInfo)
              return true
            } else {
              this.$message.error('无用户信息')
              return false
            }

          } else { // 已绑定手机号

            if (payload.user) {

              const userInfo = JSON.parse(payload.user)

              const merchantId = payload.generalUser.merchantId
              const merchants = payload.generalUser.merchants

              const newObj = merchants.filter(item => item.id === merchantId)

              if (!newObj || (newObj.length === 0)) {
                this.$message.error('无用户信息')
                return false
              }

              const corpId = newObj[0].corpId

              this.setUserInfo( Object.assign(userInfo, { corpId }) )
              return true

            } else {

              this.$message.error('无用户信息')

              return false

            }

          }

        } else {

          this.$message.error(res.error)

          return false

        }
      },
      // 清空用户信息
      clearInfo() {
        this.setToken('')
        this.setUserInfo({})
        this.setMenuList([])
        this.setAuthRoutes([])
        sessionStorage.clear()
      },
      local() {
        console.log('%c======本地调试=============', 'color: #CC0033')
        console.log('%c======本地调试=============', 'color: #339900')
      }
    }
  }
</script>

<style lang="scss" scoped>
.page {
  position: relative;
  background-image: url("//static.lianhaikeji.com/images/20210129/1a7d536e6a624ad09f12649dceebad85.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .name{
    font-size: 20px;
    color: #fff;
    line-height: 30px;
    position: absolute;
    top: 20px;
    left: 60px;
  }
}
.login-panel {
  margin: 0 auto;
  font-size: 62px;
  color: #fff;
  line-height: 80px;
  img {
    width: 348px;
    height: 66px;
    margin: 95px auto 0;
    display: block;
    cursor: pointer;
  }
}
</style>
