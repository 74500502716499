let AuthPlugin = {}
AuthPlugin.install = function (Vue, options) {
  // 实例方法
  Vue.prototype.$hasAuth = function (mid) {
    let modules = this.$store.state.user.authRoutes
    let check = false

    if (!modules) {
      return false
    }
    modules.forEach(item => {
      if (item == mid) {
        check = true
      }
    })

    return check
  }
}
export default AuthPlugin
