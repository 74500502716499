<!--
 * @Author: liuxiang
 * @Date: 2021-01-22 13:48:40
 * @LastEditors: liuxiang
 * @LastEditTime: 2021-07-06 11:40:52
 * @Description: file content
-->
<template>
  <div class="search">
    <el-form :inline="inline" :label-position="labelPosition" :label-width="labelWidth" @submit.native.prevent>
      <div>
        <slot name="headline"></slot>
      </div>
      <slot name="normal"></slot>
      <template v-if="block != undefined">
        <div><slot v-if="showMore" name="more"></slot></div>
      </template>
      <template v-else>
        <slot v-if="showMore" name="more"></slot>
      </template>
      <el-form-item v-if="this.$slots.more">
        <el-button
          v-if="!showMore"
          class="btn-more"
          type="text"
          @click="showMore = true"
        >
          高级查询
        </el-button>
        <el-button
          v-if="showMore"
          class="btn-more"
          type="text"
          @click="showMore = false"
        >
          收起
        </el-button>
      </el-form-item>
      <div class="toolbar">
        <slot name="toolbar"></slot>
      </div>
    </el-form>
  </div>
</template>

<script>
  export default {
    props: {
      block: {
        type: Boolean,
        default: false
      },
      defaultShowMore:{
        type: Boolean,
        default: false
      },
      inline: {
        type: Boolean,
        default: false
      },
      labelPosition: {
        type: String,
        default: () => {
          return 'right';
        }
      },
      labelWidth: {
        type: String,
        default: () => {
          return '100px'
        }
      }
    },
    data() {
      return {
        showMore: this.defaultShowMore
      };
    }
  };
</script>

<style lang="scss" scoped>
.search {
  position: relative;
  border: 0;
  background: #ffffff;
    border-radius: 2px;
    padding: 24px 24px 10px;
  .toolbar{
    display: flex;
    justify-content: flex-end;
  }
  /deep/ .el-input--mini .el-input__icon{
    line-height: 32px;
  }
  /deep/ .el-range-editor--mini .el-range__icon{
    line-height: 32px;
  }
}
</style>
