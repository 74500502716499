import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home'
import Login from '@/views/passport/Login'
import JumpPrompt from '@/views/passport/JumpPrompt'
import store from '@/store'

let context = require.context("./service", true, /\.js$/);
let routeList = [];
context.keys().forEach(routes => {
  routeList = routeList.concat(context(routes).default);
});

Vue.use(VueRouter)

// 获取原型对象的push函数
const originPush = VueRouter.prototype.push;
// 修改
VueRouter.prototype.push = function push(location) {
  return originPush.call(this, location).catch(err => err);
}

const routes = [
  {
    path: '/login',
    name: 'Login',
    meta: {
      free: true,
    },
    component: Login,
  },
  {
    path: '/svgTree',
    name: 'svgTree',
    meta: {
      title: 'svg贝塞尔曲线树',
    },
    component: () => import('@/components/svgTree/index'),
  },
  {
    path: '/jumpPrompt',
    name: 'JumpPrompt',
    meta: {
      free: true,
    },
    component: JumpPrompt,
  },
  {
    path: '/',
    component: Home,
    children: [
      {
        path: 'personal',
        name: 'Personal',
        meta: {
          title: '账户信息',
        },
        component: () => import('@/views/system/personal/Index'),
      },
      {
        path: 'toolbar',
        name: 'Toolbar',
        meta: {
          title: '工具栏',
        },
        component: () => import('@/views/toolbar/index'),
      },
      {
        path: '',
        redirect: {
          name: 'Dashboard'
        },
      },
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: () => import('@/views/dashboard/Index'),
      },
      ...routeList,
      {
        path: 'expirePage',
        name: 'expirePage',
        component: () => import('@/views/exception/expirePage'),
      },
      {
        path: '*',
        component: () => import('@/views/exception/404'),
      },
    ],
  },
]

const router = new VueRouter({
  routes,
})

router.beforeEach((to, from, next) => {
  const meta = to.meta
  // const token = store.state.user.token;
  // if ((!meta || !meta.free) && !token) {
  //   next('/login?redirect=' + encodeURIComponent(to.path))
  //   return
  // }
  if (meta && meta.hideSideMenu) {
    store.commit('common/setShowSideMenu', false)
  } else {
    store.commit('common/setShowSideMenu', true)
  }
  next()
})

export default router
