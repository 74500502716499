/*
 * @Author: liuxiang
 * @Date: 2021-04-06 16:18:35
 * @LastEditors: liuxiang
 * @LastEditTime: 2021-09-06 11:36:53
 * @Description: file content
 */

export default [
  {
    path: '/marketAct',
    name: 'MarketAct',
    meta: {
      title: '活动管理',
    },
    component: ()=>import('@/views/marketAct/Index'),
    children: [
      {
        path: 'act-list',
        name: 'actList',
        meta: {
          title: '我的活动',
          keepAlive: true,
        },
        component: () => import('@/views/marketAct/actList/index'),
      },
      {
        path: 'white-phone-list',
        name: 'whitePhoneList',
        meta: {
          title: '白名单详情',
        },
        component: () => import('@/views/marketAct/actList/pages/com/whitePhoneList'),
      },
      {
        path: 'act-save',
        name: "actSave",
        meta: {
          title: '新增活动',
        },
        component: () => import('@/views/marketAct/actList/pages/saveAct/index'),
      },
      {
        path: 'act-statistics',
        name: "activityStatistics",
        meta: {
          title: '活动数据统计',
        },
        component: () => import('@/views/marketAct/actList/activityStatistics/Index'),
      }
    ],
  },
]
