<template>
  <div class="clearfix">
    <el-table
      v-loading="loading"
      :data="tableData"
      fit
      row-key="id"
      highlight-current-row
      header-row-class-name="table-header"
      @selection-change="handleSelectionChange"
    >
      <slot></slot>
    </el-table>
    <!-- <span class="cost" v-if="cost">查询结果:{{ tableData.length }}条,查询耗时:{{ cost }}毫秒</span> -->
  </div>
</template>

<script>
  export default {
    props: ["url", "params", "methods"],
    data() {
      return {
        tableData: [],
        loading: false,
        cost: null
      };
    },
    watch: {
      loading(val) {
        this.$emit("update:loading", val);
      }
    },
    created() {
      this.load();
    },
    methods: {
      handleSelectionChange(arg) {
        this.$emit("selection-change", arg);
      },
      async load() {
        const startTime = Date.now();
        this.loading = true;
        let rs = null
        if(this.methods) rs=await this[this.methods=='post'?'$post':'$get'](this.url, this.params);
        else rs = await this.$post(this.url, this.params);
        if (rs.status == 200) {
          this.tableData = rs.payload;
        }
        this.cost = Date.now() - startTime;
        this.loading = false;
      }
    }
  };
</script>

<style lang="scss" scoped>
.cost {
  font-weight: normal;
  margin-right: 10px;
  font-size: 14px;
}
span {
  float: right;
  font-size: 12px;
  padding: 25px 18px 10px;
}
/deep/ .table-header{
  th{
    background-color: #FAFAFA;
  }
}
</style>
