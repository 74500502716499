export default [
  {
    path: '/customer',
    name: 'CustomerManage',
    meta: {
      title: '客户管理',
    },
    component: ()=>import('@/views/customer/Index'),
    children: [
      {
        path: 'clientele',
        name: 'CustomerClientele',
        meta: {
          title: '客户',
          keepAlive: true,
        },
        component: () => import('@/views/customer/clientele/Index'),
      },
      {
        path: 'clientele/:id',
        name: 'CustomerClienteleDetail',
        meta: {
          title: '客户详情',
        },
        component: () => import('@/views/customer/clientele/ClienteleDetail'),
      },
      {
        path: 'clienteleIntegral/:id',
        name: 'CustomerIntegralDetail',
        meta: {
          title: '积分明细',
        },
        component: () => import('@/views/customer/clientele/IntegralDetail'),
      },
      {
        path: 'group',
        name: 'CustomerGroup',
        meta: {
          title: '客户群',
          keepAlive: true,
        },
        component: () => import('@/views/customer/group/Index'),
      },
      {
        path: 'group-detail/:id',
        name: 'CustomerGroupDetail',
        meta: {
          title: '客户群详情',
        },
        component: () => import('@/views/customer/group/GroupDetail'),
      },
      {
        path: 'tag',
        name: 'CustomerTag',
        meta: {
          title: '客户标签',
        },
        component: () => import('@/views/customer/tag/Index'),
      },
      {
        path: 'resignation-inherit',
        name: 'ResignationInherit',
        meta: {
          title: '离职继承',
          keepAlive: true,
        },
        component: () => import('@/views/customer/resignationInherit/Index'),
      },
      {
        path: 'onjob-inherit',
        name: 'onjobInherit',
        meta: {
          title: '在职继承',
          keepAlive: true,
        },
        component: () => import('@/views/customer/onjobInherit/Index'),
      },

      {
        path: 'onjob-succession-assert',
        name: 'onjobSuccessionAssert',
        meta: {
          title: '在职员工企业资产',
        },
        component: () => import('@/views/customer/onjobInherit/onjobSuccessionAssert'),
      },
      {
        path: 'resignation-allocated',
        name: 'ResignationAllocated',
        meta: {
          title: '已分配离职员工资产',
        },
        component: () => import('@/views/customer/resignationInherit/Allocated'),
      },
      {
        path: 'succession-assert',
        name: 'SuccessionAssert',
        meta: {
          title: '未分配离职员工资产',
        },
        component: () => import('@/views/customer/resignationInherit/SuccessionAssert'),
      },
      {
        path: 'allocated-assert',
        name: 'AllocatedAssert',
        meta: {
          title: '已分配离职员工资产',
        },
        component: () => import('@/views/customer/resignationInherit/AllocatedAssert'),
      },
      {
        path: 'objob-resignation-allocated',
        name: 'onJobResignationAllocated',
        meta: {
          title: '已分配员工资产',
        },
        component: () => import('@/views/customer/onjobInherit/Allocated'),
      },
      {
        path: 'objob-allocated-assert',
        name: 'onJobAllocatedAssert',
        meta: {
          title: 'xx的已分配企业资产',
        },
        component: () => import('@/views/customer/onjobInherit/AllocatedAssert'),
      },
      {
        path: 'loss-remind',
        name: 'LossRemind',
        meta: {
          title: '流失提醒',
          keepAlive: true,
        },
        component: () => import('@/views/customer/lossRemind/Index'),
      },
      {
        path: 'wechat-moment',
        name: 'wechatMoment',
        meta: {
          title: '朋友圈设置',
        },
        component: () => import('@/views/customer/wechatMoment/Index'),
      },
      {
        path: 'createCircleOfFriends',
        name: 'createCircleOfFriends',
        meta: {
          title: '新建任务',
        },
        component: () => import('@/views/customer/wechatMoment/createCircleOfFriends'),
      },
      {
        path: 'circleOfFriendsDetaile',
        name: 'circleOfFriendsDetaile',
        meta: {
          title: '任务详情',
        },
        component: () => import('@/views/customer/wechatMoment/circleOfFriendsDetaile'),
      },
      {
        path: 'thumbIpDetails',
        name: 'thumbIpDetails',
        meta: {
          title: '点赞详情',
        },
        component: () => import('@/views/customer/wechatMoment/thumbIpDetails'),
      },
      {
        path: 'commentDetails',
        name: 'commentDetails',
        meta: {
          title: '评论详情',
        },
        component: () => import('@/views/customer/wechatMoment/commentDetails'),
      },
      {
        path: 'personal-sop',
        name: 'personalSop',
        meta: {
          title: '个人SOP',
          keepAlive: true,
        },
        component: () => import('@/views/customer/personalSOP/Index'),
      },
      {
        path: 'personal-rule-detail',
        name: 'personalRuleDetail',
        meta: {
          title: '个人SOP详情',
        },
        component: () => import('@/views/customer/personalSOP/personalRuleDetail'),
      },
      {
        path: 'old-detail-set',
        name: 'detailSet',
        meta: {
          title: '客户详情设置',
        },
        component: () => import('@/views/customer/detailSet/Index'),
      },
      {
        path: 'detail-set',
        name: 'newDetailSet',
        meta: {
          title: '客户详情设置',
        },
        component: () => import('@/views/customer/detailSet/NewIndex'),
      },
      {
        path: 'businessCard',
        name: 'businessCard',
        meta: {
          title: '个人名片',
        },
        component: () => import('@/views/customer/businessCard/index'),
      },
      {
        path: 'businessCard-set',
        name: 'businessCardSet',
        meta: {
          title: '名片设置',
        },
        component: () => import('@/views/customer/businessCard/set'),
      },
      {
        path: 'clue',
        name: 'Clue',
        meta: {
          title: '线索转化',
          keepAlive: true,
        },
        component: () => import('@/views/customer/clue/index'),
      },
      {
        path: 'clue-detail/:id',
        name: 'ClueDetail',
        meta: {
          title: '线索详情',
        },
        component: () => import('@/views/customer/clue/clueDetail'),
      },
      {
        path: 'customerIndex',
        name: 'CustomerIndex',
        meta: {
          title: '客户画像',
        },
        component: () => import('@/views/customer/clientele/CustomerIndex'),
      },
      // 锁客二维码
      {
        path: 'lock-customer',
        name: 'LockCustomer',
        meta: {
          title: '锁客二维码',
          keepAlive: true,
        },
        component: () => import('@/views/customer/lockCustomer'),
        children: [
          {
            path: 'customerQRAnalysis',
            name: 'customerQRAnalysis',
            meta: {
              title: '数据分析',
            },
            component: () => import('@/views/customer/lockCustomer/analysis'),
          }
        ]
      },
      // 新建锁客二维码
      {
        path: 'newCustomerQR',
        name: 'newCustomerQR',
        meta: {
          title: '新建锁客二维码',
        },
        component: () => import('@/views/customer/lockCustomer/links/component/newCustomerQR'),
      }
    ],
  },
]
