<template>
  <div v-if="menu.type != 'HIDDEN'" style="white-space: nowrap;">
    <div class="menu-name" :class="{
        'menu-name-active': $route.path == menu.router,
        'menu-name-active-hide': $route.path === menu.router && !showSide,
      }" @click="onMenuClick">
      <!-- <i
        v-show="menu.level <= 2"
        class="iconfont"
        style="font-size: 18px"
        :class="menu.icon || 'el-icon-menu'"
      ></i> -->
      <!-- <img :src="menu.icon" alt="" /> -->
      <i class="iconfont" :class="menu.icon"></i>
      <template v-if="showSide">
        <span style="margin-left: 10px;position: relative" :style="{fontSize: menu.level==2?'14px':'12px'}">{{ menu.mname }}
          <!-- <img v-if="menu.moduleVersion=='1'" class="right-top-tag" src="//static.lianhaikeji.com/images/20210609/bcc4801972bc46d2a5043c9f6a915407.png" alt="内侧"> -->
          <img v-if="menu.moduleVersion=='1'" class="right-top-tag" src="//oss.zzkj8888.com/images/20211220/335e9171db944193931e9b989ceeecd5.png" alt="NEW">
        </span>
        <i v-if="hasChildren && menu.type == 'NODE'" class="arrow el-icon-arrow-down" :class="{ 'arrow-ref': showChildren }">
        </i>
      </template>
    </div>
    <div v-if="showChildren && hasChildren" class="menu-children">
      <menu-item v-for="child in menu.children" v-show="child.type != 'BUTTON'" :key="child.mid" :menu="child" :show-side="showSide">
      </menu-item>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'MenuItem',
    props: {
      menu: {
        type: Object,
        default: () => {
          return {}
        },
      },
      showSide: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        showChildren: true,
        hasChildren: this.menu.children && this.menu.children.length > 0,
      }
    },
    watch: {
      showSide(val) {
        this.showChildren = val
      },
    },
    methods: {
      onMenuClick() {

        if(this.menu.type == 'URL' && !this.menu.router && this.$route.name !== 'expirePage') {
          this.$router.push({name: 'expirePage'})
        }
        if (
          this.menu.type == 'URL' &&
          this.menu.router &&
          this.menu.router.startsWith('http')
        ) {
          window.open(this.menu.router)
          return false
        }
        if (
          this.menu.type == 'URL' &&
          this.$route.path != this.menu.router &&
          this.menu.router
        ) {
          this.$router.push(this.menu.router)
          return false
        }
        if (this.menu.type == 'NODE') {
          if (this.showSide) {
            this.showChildren = !this.showChildren
            return false
          }
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
.menu-name {
  position: relative;
  height: 46px;
  line-height: 46px;
  padding: 0 20px;
  cursor: pointer;
  color: #fff;
  font-size: 14px;
  transition: all 0.2s;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  img {
    //width: 19px;
    //height: 19px;
    margin-left: 8px;
  }
  .right-top-tag {
    position: absolute;
    right: -12px;
    top: -2px;
    width: 24px;
    height: 16px;
    object-fit: cover;
  }

  &:hover {
    color: #ffffff;
    background: #6178ff;
  }

  .arrow {
    position: absolute;
    top: 50%;
    right: 10px;
    margin-top: -9px;
    transition: all 0.3s;
  }

  .arrow-ref {
    transform: rotate(180deg);
  }
}

.menu-name-active {
  color: #ffffff !important;
  background: #6178ff;

  &::before {
    content: " ";
    position: absolute;
    top: 0;
    left: 1px;
    bottom: 0;
    border-left: 4px solid #498bf7;
  }
}

.menu-name-active-hide {
  &::before {
    content: none;
  }
}

.menu-children {
  .menu-name {
    padding-left: 40px;
    color: rgba($color: #ffffff, $alpha: 0.7);
  }
}
</style>
