export default [
  {
    path: '/marketing',
    name: 'marketingActivity',
    meta: {
      title: '营销活动',
    },
    component: () => import('@/views/marketing/Index'),
    children: [
      {
        path: 'third',
        name: 'thirdActivity',
        meta: {
          title: '第三方活动',
        },
        component: () => import('@/views/marketing/thirdActivity/Index'),
      },
      {
        path: 'supplierList',
        name: 'supplierList',
        meta: {
          title: '供应商管理',
        },
        component: () => import('@/views/marketing/supplierList/Index'),
      },
      {
        path: 'goodsList',
        name: 'goodsList',
        meta: {
          title: '商品管理',
        },
        component: () => import('@/views/marketing/goodsList/Index'),
      },
      {
        path: 'saveGood',
        name: 'saveGood',
        meta: {
          title: '新增商品',
        },
        component: () => import('@/views/marketing/goodsList/saveGood'),
      },
      {
        path: 'goodsSortList',
        name: 'goodsSortList',
        meta: {
          title: '分类管理',
        },
        component: () => import('@/views/marketing/goodsSortList/Index'),
      },
      {
        path: 'stockList',
        name: 'stockList',
        meta: {
          title: '库存管理',
        },
        component: () => import('@/views/marketing/stockList/Index'),
      },
      {
        path: 'stockTurnOver',
        name: 'stockTurnOver',
        meta: {
          title: '库存流水',
        },
        component: () => import('@/views/marketing/stockTurnOver/Index'),
      },
      {
        path: 'crowdPack',
        name: 'crowdPack',
        meta: {
          title: '人群包管理',
        },
        component: () => import('@/views/marketing/crowdPack/Index'),
      },
      {
        path: 'addActivity',
        name: 'addActivity',
        meta: {
          title: '创建活动',
        },
        component: () => import('@/views/marketing/thirdActivity/add'),
      },
      {
        path: 'thirdActivityDetail',
        name: 'thirdActivityDetail',
        meta: {
          title: '活动详情',
        },
        component: () => import('@/views/marketing/thirdActivity/detail'),
      },
      {
        path: 'activityCode',
        name: 'activityCode',
        meta: {
          title: '活动代码',
        },
        component: () => import('@/views/marketing/thirdActivity/activityCode'),
      },
      {
        path: 'transactionOrder',
        name: 'transactionOrder',
        meta: {
          title: '交易单',
        },
        component: () => import('@/views/marketing/transactionOrder/index'),
      },
      {
        path: 'recharge',
        name: 'recharge',
        meta: {
          title: '充值',
        },
        component: () => import('@/views/marketing/transactionOrder/recharge'),
      },
      {
        path: 'rechargeRecord',
        name: 'rechargeRecord',
        meta: {
          title: '充值记录',
        },
        component: () => import('@/views/marketing/transactionOrder/rechargeRecord'),
      },
      {
        path: 'rechargeWay',
        name: 'rechargeWay',
        meta: {
          title: '充值方式',
        },
        component: () => import('@/views/marketing/transactionOrder/rechargeWay'),
      },
      {
        path: 'rechargeStatus',
        name: 'rechargeStatus',
        meta: {
          title: '充值后状态',
        },
        component: () => import('@/views/marketing/transactionOrder/rechargeStatus'),
      },
      {
        path: 'orderMange',
        name: 'orderMange',
        meta: {
          title: '订单管理',
        },
        component: () => import('@/views/marketing/orderMange/index'),
      },
    ]
  }
]