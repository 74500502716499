/*
 * @Author: liuxiang
 * @Date: 2021-04-06 16:18:35
 * @LastEditors: liuxiang
 * @LastEditTime: 2021-07-26 11:39:57
 * @Description: file content
 */
export default [{
    path: '/wechat',
    name: 'Wechat',
    meta: {
      title: '授权',
    },
    component: () => import('@/views/system/Index'),
    children: [{
      path: 'officeAuthorization',
      name: 'officeAuthorization',
      meta: {
        title: '公众号授权',
      },
      component: () => import('@/views/wechat/officeAuthorization'),
    }]
  },
  {
    path: '/system',
    name: 'SystemManage',
    meta: {
      title: '系统管理',
    },
    component: () => import('@/views/system/Index'),
    children: [{
        path: 'role',
        name: 'RoleManage',
        meta: {
          title: '角色管理',
        },
        component: () => import('@/views/system/role/Index'),
      },
      {
        path: 'module',
        name: 'ModuleManage',
        meta: {
          title: '模块管理',
        },
        component: () => import('@/views/system/module/Index'),
      },
      {
        path: 'org-structure',
        name: 'OrgStructure',
        meta: {
          title: '组织架构',
        },
        component: () => import('@/views/system/orgStructure/Index'),
      },
      {
        path: 'workwx-config',
        name: 'workwxConfig',
        meta: {
          title: '企微配置',
        },
        component: () => import('@/views/system/workwxConfig/Index'),
      },
      {
        path: 'api-access',
        name: 'apiAccess',
        meta: {
          title: 'API接入',
        },
        component: () => import('@/views/system/apiAccess/Index'),
      },
      {
        path: 'verify-file',
        name: 'VerifyFile',
        meta: {
          title: '校验文件上传',
        },
        component: () => import('@/views/system/verifyFile/index'),
      }
      
    ],
  },
]
