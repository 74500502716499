/*
 * @Author: liuxiang
 * @Date: 2021-05-13 10:13:33
 * @LastEditors: huangjing
 * @LastEditTime: 2021-09-14 14:34:30
 * @Description: file content
 */
import axios from 'axios'

class activityApi {
  /**
   * @description: 获取公众号授权
   * @param {*}
   * @return {*}
   */

  createAuthurl(data) {
    let url = '/api/oauth/create/authurl'
    return axios.post(url)
  }

  /**
   * @description: 公众号授权成功回调
   * @param {*}
   * @return {*}
   */

  pubAccAuthurl(authCode) {
    let url = '/api/oauth/pubAcc/oauth?authCode=' + authCode
    return axios.get(url)
  }

  /**
   * @description: 查询已授权公众号列表
   * @param {*}
   * @return {*}
   */

  pubFlatAccQueryList() {
    let url = '/api/oauth/pubAcc/queryList'
    return axios.post(url)
  }

  /**
   * @description: 设置企业默认公众号
   * @param {*}
   * @return {*}
   */

   defaultPubAcc(data) {
    let url = '/api/oauth/default/pubAcc'
    return axios.post(url, data)
  }


  /**
   * @description: 创建裂变活动
   * @param {*}
   * @return {*}
   */

  addNewAct(data) {
    let url = '/api/fiss/addNew'
    return axios.post(url, data)
  }


  /**
   * @description: 设置邀请链接
   * @param {*}
   * @return {*}
   */

   fissSaveLink(data) {
    let url = '/api/fiss/saveLink'
    return axios.post(url, data)
  }

  /**
   * @description: 保存宣传海报
   * @param {*}
   * @return {*}
   */

  addNewActSetPoster(data) {
    let url = '/api/fiss/setPoster'
    return axios.post(url, data)
  }
  /**
   * @description: 保存裂变海报
   * @param {*}
   * @return {*}
   */

  addNewActSetFissionPoster(data) {
    let url = '/api/fiss/setFissionPoster'
    return axios.post(url, data)
  }

  /**
   * @description: 活动详情
   * @param {*}
   * @return {*}
   */
  fissDetail(data) {
    let url = '/api/fiss/queryDetail'
    return axios.post(url, data)
  }

  /**
   * @description: 编辑活动
   * @param {*}
   * @return {*}
   */
  fissUpdate(data) {
    let url = '/api/fiss/update'
    return axios.post(url, data)
  }
  /**
   * @description: 活动客户总览
   * @param {*}
   * @return {*}
   */
  fissCustomerView(data) {
    let url = '/api/fissCustomer/overview'
    return axios.post(url, data)
  }

  
  /**
   * @description: 活动内容总览
   * @param {
   *   id  活动id
   * }
   * @return {*}
   */
   fissOverviewDetail(data) {
    let url = '/api/fiss/overviewDetail'
    return axios.post(url, data)
  }
  /**
   * @description: 删除裂变活动
   * @param {*}
   * @return {*}
   */
  fissDelete(data) {
    let url = '/api/fiss/delete'
    return axios.post(url, data)
  }
  /**
   * @description: 群裂变活动详情
   * @param {*}
   * @return {*}
   */

  groupFissDetail(data) {
    let url = '/api/groupFiss/act/detail'
    return axios.post(url, data)
  }

  /**
   * @description: 创建群裂变活动
   * @param {*}
   * @return {*}
   */

  groupAddNewAct(data) {
    let url = '/api/groupFiss/act/addNew'
    return axios.post(url, data)
  }

  /**
   * @description: 编辑群裂变活动
   * @param {*}
   * @return {*}
   */
  groupFissUpdate(data) {
    let url = '/api/groupFiss/act/update'
    return axios.post(url, data)
  }

  /**
   * @description: 保存裂变海报
   * @param {*}
   * @return {*}
   */
  groupSavePoster(data) {
    let url = '/api/groupFiss/act/savePoster'
    return axios.post(url, data)
  }

   /**
   * @description: 个人裂变邀请客户参与
   * @param {*}
   * @return {*}
   */
    inviteCustomers(data) {
      let url = '/api/fiss/inviteCustomers'
      return axios.put(url, data)
    }
     /**
     * @description: 个人裂变新建活动
     * @param {*}
     * @return {*}
     */
    fiss(data) {
      let url = '/api/fiss/save'
      return axios.post(url, data)
    }
     /**
     * @description: 个人裂变活动详情
     * @param {*}
     * @return {*}
     */
    fissPut(data) {
      let url = '/api/fiss/update'
      return axios.put(url, data)
    }

  /**
   * @description: 设置群聊与欢迎语
   * @param {*}
   * @return {*}
   */
   groupAndInvite(data) {
    let url = '/api/groupFiss/act/addActivity'
    return axios.post(url, data)
  }

  // 编辑群裂变活动
  groupUpdateActivity(data) {
    let url = '/api/groupFiss/act/updateActivity';
    return axios.post(url, data)
  }
     /** @description: 查询订单提货记录列表
 * @return {*}
 */
  getFishRecordList(orderCode) {
    let url = `/api/act/order/getFishRecordList/${orderCode}`
    return axios.get(url)
  }
  /**
* @description: 解冻或冻结
* @return {*}
*/
  freezeOrder(orderCode) {
    let url = `/api/act/order/freezeOrder/${orderCode}`
    return axios.post(url)
  }
  /**
   * @description: 邀请客户参与
   * @param {*}
   * @return {*}
   */
  groupNotifyEmp(data) {
    let url = '/api/groupFiss/act/notifyEmp'
    return axios.post(url, data)
  }
  
  /**
   * @description: 删除裂变活动
   * @param {*}
   * @return {*}
   */
   groupfissDelete(data) {
    let url = '/api/groupFiss/act/del'
    return axios.post(url, data)
  }
  
  /**
   * @description: 活动数据总览
   * @param {
   *  id  活动id
   * }
   * @return {*}
   */
   groupfissOverview(data) {
    let url = '/api/groupFiss/act/overview'
    return axios.post(url, data)
  }

  /**
   * @description: 活动内容总览
   * @param {
   *   id  活动id
   * }
   * @return {*}
   */
   groupfissOverviewDetail(data) {
    let url = '/api/groupFiss/act/overviewDetail'
    return axios.post(url, data)
  }

  /**
   * @description: 供应商列表
   * @param {
   *   name  供应商名称
   *   current
   *   size
   * }
   * @return {*}
   */
   getSupplierPager(data) {
    let url = '/api/act/supplier/getSupplierPager'
    return axios.post(url, data)
  }
  /**
   * @description: 保存供应商
   * @param {
   *   id  
   *   name  供应商名称
   *   appKey
   *   appSecret
   * }
   * @return {*}
   */
   saveSupplier(data) {
    let url = '/api/act/supplier/saveSupplier'
    return axios.post(url, data)
  }
  /**
   * @description: 删除供应商
   * @param {
   *   id  
   * }
   * @return {*}
   */
   deleteSupplier(id) {
    let url = '/api/act/supplier/deleteSupplier?id=' + id
    return axios.post(url)
  }
  /**
   * @description: 保存商品分类
   * @param {
   *   id  
   * }
   * @return {*}
   */
   saveProductGroup(data) {
    let url = '/api/act/pro/saveProductGroup'
    return axios.post(url, data)
  }
  /**
   * @description: 删除商品分类
   * @param {
   *   id  
   * }
   * @return {*}
   */
   delProductGroup(groupId) {
    let url = '/api/act/pro/delProductGroup?groupId='+groupId
    return axios.post(url)
  }
  /**
   * @description: 获取商品分类列表
   * @param {
   *   id  
   * }
   * @return {*}
   */
   getProductGroup() {
    let url = '/api/act/pro/getProductGroup'
    return axios.post(url, {})
  }
  /**
   * @description: 获取商品规格列表
   * @param {
   *   id  
   * }
   * @return {*}
   */
   getProStandards(data) {
    let url = '/api/act/pro/getProStandards?proId=' + data.proId
    return axios.post(url)
  }
  /**
   * @description: 保存商品
   * @param {}
   * @return {*}
   */
   saveProduct(data) {
    let url = '/api/act/pro/saveProduct'
    return axios.post(url, data)
  }
  /**
   * @description: 飞鱼商品列表
   * @param {
   *   id  
   * }
   * @return {*}
   */
   getFishProducts(data) {
    let url = '/api/act/pro/getFishProducts?supplierId=' + data.supplierId
    return axios.post(url, data)
  }
  /**
   * @description: 飞鱼规格列表
   * @param {
   *   id  
   * }
   * @return {*}
   */
   getFishProductSubs(data) {
    let url = `/api/act/pro/getFishProductSubs?supplierId=${data.supplierId}&proId=${data.proId}`
    return axios.post(url)
  }
  /**
   * @description: 商品详情
   * @param {
   *   id  
   * }
   * @return {*}
   */
   getProductDetail(id) {
    let url = `/api/act/pro/getProductDetail?productId=${id}`
    return axios.post(url)
  }
  /**
   * @description: 商品分页列表
   * @param {
   *   current
   *   size  
   * }
   * @return {*}
   */
   getActProductPager(data) {
    let url = `/api/act/pro/getActProductPager`
    return axios.post(url, data)
  }
  /**
   * @description: 规格分页列表
   * @param {
   *   current
   *   size  
   * }
   * @return {*}
   */
   getProStandardsList(data) {
    let url = `/api/act/pro/getProStandardsList`
    return axios.post(url, data)
  }
  /**
   * @description: 字典接口
   * @param {
   *   type  
   * }
   * @return {*}
   */
   getDictionariesByType(type) {
    let url = `/api/act/dictionaries/getDictionariesByType/${type}`
    return axios.post(url)
  }
  /**
   * @description: 下载号码人群包模板
   * @param {
   *   type  
   * }
   * @return {*}
   */
   crowdPackEDownload() {
    let url = `/api/act/crowdPack/excelDownload`
    return axios.post(url)
  }
  /**
   * @description: 新增编辑人群包
   * @param {
   *   type  
   * }
   * @return {*}
   */
   saveCrowdPack(data) {
    let url = `/api/act/crowdPack/saveCrowdPack`
    return axios.post(url, data)
  }
  /**
   * @description: 新增一条号码人群包
   * @param {
   *   type  
   * }
   * @return {*}
   */
   addOneMobile(data) {
    let url = `/api/act/crowdPack/addOneMobile`
    return axios.post(url, data)
  }
  /**
   * @description: 获取人群包详情
   * @param {
   *   type  
   * }
   * @return {*}
   */
   getCrowdPackDetail(data) {
    let url = `/api/act/crowdPack/getCrowdPackDetail`
    return axios.post(url, data)
  }
  /**
   * @description: 修改人群包状态
   * @param {
   *   id  
   * }
   * @return {*}
   */
   updateStatusCrowdPackById(id) {
    let url = `/api/act/crowdPack/updateStatusCrowdPackById/${id}`
    return axios.post(url)
  }
  /**
   * @description: 查询库存列表
   * @param {
   *   id  
   * }
   * @return {*}
   */
   getStockPager(id) {
    let url = `/api/act/stock/getStockPager`
    return axios.post(url)
  }
  /**
   * @description: 批量商品入库筛选
   * @param {
   *   productName 商品名称
   *   supplierName 供应商名称
   * }
   * @return {*}
   */
   getBatchStockList(data) {
    let url = `/api/act/stock/getStockList`
    return axios.post(url, data)
  }
  /**
   * @description: 调整库存
   * @param {
   *   supplierId 供应商id
   *   stockNumList 数据列表
   * }
   * @return {*}
   */
   changeStock(data) {
    let url = `/api/act/stock/changeStock`
    return axios.post(url, data)
  }
  /**
   * @description: 更新供应商余额
   * @return {*}
   */
   updatePriceBalance(id) {
    let url = `/api/act/supplier/updateBalance?id=` + id
    return axios.post(url)
  }
  /**
   * @description: 人群包列表
   * @return {*}
   */
   getCrowdPackPager(data) {
    let url = `/api/act/crowdPack/getCrowdPackPager`
    return axios.post(url, data)
  }
  /**
   * @description: 模板列表
   * @return {*}
   */
   getListTemplate(data) {
    let url = `/api/act/template/listTemplate`
    return axios.post(url, data)
  }
  /**
   * @description: 新增活动第一步 编辑活动基础参数
   * @return {*}
   */
   createBaseActivity(data) {
    let url = `/api/activity/createBaseActivity`
    return axios.post(url, data)
  }
  /**
   * @description: 新增活动第二步 添加活动奖品
   * @return {*}
   */
   addActivityPrize(data) {
    let url = `/api/activity/addActivityPrize`
    return axios.post(url, data)
  }
  /**
   * @description: 新增活动第三步 保存模板
   * @return {*}
   */
   activityConfigData(data) {
    let url = `/api/activity/activityConfigData`
    return axios.post(url, data)
  }
  /**
   * @description: 根据模板ID获取模板详情
   * @return {*}
   */
   getTemplateDetail(templateId) {
    let url = `/api/act/template/getTemplateDetail/` + templateId
    return axios.post(url)
  }
  /**
   * @description: 根据活动id获取活动展示内容
   * @return {*}
   */
   getActConfigData(actId) {
    let url = `/api/activity/getActConfigData/` + actId
    return axios.get(url)
  }
  /**
   * @description: 获取基础活动配置
   * @return {*}
   */
   getBaseActivityConfigUsingGET(actId) {
    let url = `/api/activity/getBaseActivityConfig/` + actId
    return axios.get(url)
  }
  /**
   * @description: 删除限制组
   * @return {*}
   */
   deleteLimitGroup(actId) {
    let url = `/api/act/limitGroup/deleteLimitGroup?id=${actId}`
    return axios.post(url)
  }
  /**
   * @description: 删除限制组
   * @param {
   *  status 状态0关闭，1开启 示例：0
   *  id 限制组id 示例：2
   * }
   * @return {*}
   */
   updateLimitGroupStatus(data) {
    let url = `/api/act/limitGroup/updateLimitGroupStatus`
    return axios.post(url, data)
  }
  /**
   * @description: 查询限制组属性
   * @param {*}
   * @return {*}
   */
   queryLimitPropertyInfo() {
    let url = `/api/act/limitGroup/queryLimitPropertyInfo`
    return axios.post(url)
  }
  /**
   * @description: 抽奖活动 编辑活动限制
   * @param {*}
   * @return {*}
   */
   editActLimitGroup(data) {
    let url = `/api/activity/editActLimitGroup`
    return axios.post(url, data)
  }
  /**
   * @description: 获取所有限制组
   * @param {*}
   * @return {*}
   */
   queryLimitGroupOptions() {
    let url = `/api/act/limitGroup/queryLimitGroupList`
    return axios.post(url)
  }
  /**
   * @description: 保存限制组
   * @param {*}
   * @return {*}
   */
   saveLimitGroup(data) {
    let url = `/api/act/limitGroup/saveLimitGroup`
    return axios.post(url, data)
  }
  /**
   * @description: 限制组回显
   * @param {*}
   * @return {*}
   */
   getLimitGroupConfig(limitGroupId) {
    let url = `/api/act/limitGroup/getLimitGroupConfig?limitGroupId=${limitGroupId}`
    return axios.post(url)
  }
  /**
   * @description: 白名单分页列表
   * @param {*}
   * @return {*}
   */
   queryWhiteListPage(data) {
    let url = `/api/act/whiteList/queryWhiteListPage`
    return axios.post(url, data)
  }
  /**
   * @description: 白名单手机号分页列表
   * @param {*}
   * @return {*}
   */
   queryWhiteListDetailPage(data) {
    let url = `/api/act/whiteList/queryWhiteListDetailPage`
    return axios.post(url, data)
  }
  /**
   * @description: 删除白名单
   * @param {*}
   * @return {*}
   */
   deleteWhiteList(whiteId) {
    let url = `/api/act/whiteList/deleteWhiteList?whiteId=${whiteId}`
    return axios.post(url)
  }
  /**
   * @description: 删除白名单手机
   * @param {*}
   * @return {*}
   */
   deleteWhiteMobile(whitePhoneId) {
    let url = `/api/act/whiteList/deleteWhiteMobile?detailId=${whitePhoneId}`
    return axios.post(url)
  }
  /**
   * @description: 保存白名单
   * @param {*}
   * @return {*}
   */
   saveWhiteList(data) {
    let url = `/api/act/whiteList/saveWhiteList`
    return axios.post(url, data)
  }
  /**
   * @description: 导入白名单手机号
   * @param {*}
   * @return {*}
   */
   importWhiteMobile(data) {
    let url = `/api/act/whiteList/importWhiteMobile`
    return axios.post(url, data)
  }
  /**
   * @description: 添加手机号
   * @param {*}
   * @return {*}
   */
   saveWhiteListMobile(data) {
    let url = `/api/act/whiteList/saveWhiteListMobile`
    return axios.post(url, data)
  }
  /**
   * @description: 不分页白名单
   * @param {*}
   * @return {*}
   */
   queryWhiteList() {
    let url = `/api/act/whiteList/queryWhiteList`
    return axios.post(url)
  }
    /**
   * @description: 查询所有合米抽奖活动名称
   * @param {*}
   * @return {*}
   */
  queryActNameList() {
    let url = `/api/activity/queryActNameList`
    return axios.post(url)
  }

}
export default new activityApi()