export default [
  {
    path: 'save-canvas',
    name: 'SaveCanvas',
    meta: {
      title: '新建画布',
    },
    component: () => import('@/views/marketingJourney/canvasManage/page/saveCanvas'),
  },
  {
    path: 'canvas-page',
    name: 'CanvasPage',
    meta: {
      title: '画布页面',
      hideLeftMenu: true // 隐藏左侧菜单栏
    },
    component: () => import('@/views/marketingJourney/canvasManage/page/canvasPage'),
  },
  {
    path: 'canvas-detail',
    name: 'CanvasDetail',
    meta: {
      title: '画布详情',
    },
    component: () => import('@/views/marketingJourney/canvasManage/page/detail'),
  },
  {
    path: '/canvas-manage',
    name: 'CanvasManage',
    meta: {
      title: '画布管理',
    },
    component: ()=>import('@/views/marketingJourney/canvasManage/index')
  },
  {
    path: '/customer-base',
    name: 'CustomerBase',
    meta: {
      title: '客户群',
    },
    component: ()=>import('@/views/marketingJourney/customerBase/index'),
    children: [
      {
        path: 'customer-pool',
        name: 'CustomerPool',
        meta: {
          title: '客户池',
        },
        component: () => import('@/views/marketingJourney/customerPool/index'),
      }
    ]
  },
  {
    path: 'importRecord',
    name: 'importRecord',
    meta: {
      title: '导入记录',
    },
    component: () => import('@/views/marketingJourney/customerPool/page/importRecord'),
  },
  {
    path: 'customerDetail',
    name: 'customerDetail',
    meta: {
      title: '客户详情',
    },
    component: () => import('@/views/marketingJourney/customerPool/page/customerDetail'),
  },
  {
    path: 'attribute',
    name: 'Attribute',
    meta: {
      title: '属性管理',
    },
    component: () => import('@/views/marketingJourney/attribute/Index'),
  },
  {
    path: 'attribute-group',
    name: 'AttributeGroup',
    meta: {
      title: '属性集管理',
    },
    component: () => import('@/views/marketingJourney/attribute/groupManage'),
  },
  {
    path: 'tag-management',
    name: 'TagManagement',
    meta: {
      title: '标签管理',
    },
    component: () => import('@/views/marketingJourney/tagManagement/index'),
  },
  {
    path: 'salesManagement',
    name: 'SalesManagement',
    meta: {
      title: '销售管理',
    },
    component: () => import('@/views/salesManagement/index'),
    children: [
      {
        path: 'enterprisesSet',
        name: 'EnterprisesSet',
        meta: {
          title: '企业设置',
        },
        component: () => import('@/views/salesManagement/components/enterprisesSet'),
      },
    ]
  },
  {
    path: 'salesSeas',
    name: 'SalesSeas',
    meta: {
      title: '销售公海',
    },
    component: () => import('@/views/salesSeas/index'),
  },
  {
    path: 'customerRechecking',
    name: 'CustomerRechecking',
    meta: {
      title: '客户查重',
    },
    component: () => import('@/views/customerRechecking/index'),
  },
  {
    path: 'saleDetail/:id',
    name: 'SaleDetail',
    meta: {
      title: '客户详情',
    },
    component: () => import('@/views/salesSeas/clueDetail'),
  },
  {
    path: 'corpGS',
    name: 'CorpGS',
    meta: {
      title: '企业工商信息',
    },
    component: () => import('@/views/salesManagement/components/corpGS'),
  },
  
  {
    path: 'customerSalesDetail',
    name: 'CustomerSalesDetail',
    meta: {
      title: '销售客户详情',
    },
    component: () => import('@/views/customerManagement/components/customerSalesDetail'),
  },
  {
    path: 'customerManagement',
    name: 'CustomerManagement',
    meta: {
      title: '客户管理',
    },
    component: () => import('@/views/customerManagement/index'),
    children: [
      {
        path: 'salesCustomer',
        name: 'SalesCustomer',
        meta: {
          title: '企业设置',
        },
        component: () => import('@/views/customerManagement/components/salesCustomer'),
      }
    ]
  }
]