<!--
 * @Author: liuxiang
 * @Date: 2021-06-18 16:02:13
 * @LastEditors: liuxiang
 * @LastEditTime: 2021-06-30 09:41:14
 * @Description: 微信公众号设置
-->
<template>
  <div>
    <div class="flex-contain flex-main-justify wechatAccount">
      公众号：<img
        :src="
          pubAccDefault.headImg ||
          ' //static.lianhaikeji.com/images/20210519/06f835f8181f45508ec79fae6511138b.png'
        "
        alt=""
      />{{ pubAccDefault.mpName ? pubAccDefault.mpName : "暂未设置" }}
      <i
        v-if="setStatus"
        class="icon el-icon-setting"
        @click="dialogVisible = true"
      ></i>
    </div>

    <el-dialog
      title="设置"
      class="create-dialog"
      :visible.sync="dialogVisible"
      width="460px"
    >
      <div class="wechatSet">
        <div class="wechatSet-title">从已授权的公众号选择：</div>
        <div class="wechatSet-tip flex-contain">
          <div class="icon">
            <i class="el-icon-warning"></i>
          </div>
          <p class="flex-1">
            请选择满足：1. 已认证服务号；2. 绑定在企业微信开放平台的公众号。
          </p>
        </div>
        <div class="wechatSet-list">
          <div
            v-for="(item, index) in pubFlatAccList"
            :key="index"
            class="wechatSet-list-item flex-contain"
            :class="{
              disable:
                item.serviceType != 2 ||
                item.verifyType == -1 ||
                item.verifyType == 1 || !isHave24(item.funcList),
              active: index == pubFlatAccIndex,
            }"
            @click="
              item.serviceType != 2 ||
              item.verifyType == -1 ||
              item.verifyType == 1 || !isHave24(item.funcList)
                ? ''
                : (pubFlatAccIndex = index)
            "
          >
            <img
              :src="
                item.headImg ||
                '//oss.zzkj8888.com/images/20210519/06f835f8181f45508ec79fae6511138b.png'
              "
              alt=""
            />
            <div class="flex-1">
              <div class="wechatSet-list-item-title">{{ item.mpName }}
                <span v-if="!isHave24(item.funcList)" class="error">未授权开放平台账号管理权限</span>
              </div>
              <div class="wechatSet-list-item-type flex-contain">
                <template v-if="item.serviceType == 2"> 服务号 </template>
                <template v-else> 订阅号 </template>
                <div
                  v-if="item.verifyType == -1 || item.verifyType == 1"
                  class="ml-10"
                >
                  <el-tag type="danger" size="mini">未认证</el-tag>
                </div>
                <div v-else class="ml-10">
                  <el-tag type="success" size="mini">已认证</el-tag>
                </div>
              </div>
            </div>
            <div class="iconSuccess">
              <i class="el-icon-success"></i>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
<!--        <el-button style="float: left" type="text" @click="addAccount"
          >添加授权公众号</el-button
        >-->
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="setDefaultPubAcc">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import activityApi from "@/api/activity/index";
  export default {
    name: "",
    props: {
      setStatus: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        pubFlatAccList: [],
        pubFlatAccIndex: -1,
        dialogVisible: false,
      };
    },
    computed: {
      pubAccDefault() {
        let that = this;
        let arr = this.pubFlatAccList.filter((item, index) => {
          if (item.defaultFlag == 1) {
            that.$store.commit("common/setWechatPubFlatAcc", item);
            that.pubFlatAccIndex = index;
          }
          return item.defaultFlag == 1;
        });
        return arr.length > 0 ? arr[0] : {};
      },
    },
    created() {
      this.getPubFlatAccQueryList();
    },
    methods: {
      isHave24(arr) {
        return arr.indexOf("24") > -1;
      },
      // 获取公众号
      async getPubFlatAccQueryList() {
        let rs = await activityApi.pubFlatAccQueryList();
        if (rs.status == 200) {
          this.pubFlatAccList = rs.payload;
          if (rs.payload.length === 0) {
            this.$store.commit("common/setWechatPubFlatAcc", null);
          }
        }
      },
      // 添加授权公众号
      async setDefaultPubAcc() {
        if (this.pubFlatAccIndex == -1) {
          this.dialogVisible = false;
          return;
        }
        let rs = await activityApi.defaultPubAcc({
          appId: this.pubFlatAccList[this.pubFlatAccIndex].authorizerAppid,
        });
        if (rs.status == 200) {
          this.$message.success("设置成功");
          this.getPubFlatAccQueryList();
          this.dialogVisible = false;
        }
      },
      async addAccount() {
        let res = await activityApi.createAuthurl();
        if (res.status == 200) {
          var winObj = window.open(res.payload);
          var loop = setInterval(() => {
            if (winObj.closed) {
              clearInterval(loop);
              this.getPubFlatAccQueryList();
            }
          }, 1000);
        } else {
          this.$message.error(res.error)
        }
      },
    },
  };
</script>
<style lang='scss' scoped>
.wechatAccount {
  padding: 5px 16px;
  height: 32px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.09);
  font-size: 14px;
  color: #2c313b;
  line-height: 20px;
  img {
    width: 20px;
    height: 20px;
    margin: 0 5px;
  }
  .icon {
    color: #999;
    margin-left: 10px;
    cursor: pointer;
    font-size: 18px;
  }
}

.wechatSet {
  padding: 24px;
  &-title {
    font-size: 16px;
    color: #2c313b;
    line-height: 22px;
    margin-bottom: 8px;
  }
  &-tip {
    width: 100%;
    height: 60px;
    background: rgba(44, 49, 59, 0.04);
    border-radius: 2px 2px 0px 0px;
    padding: 0 16px;
    margin-bottom: 24px;
    .icon {
      height: 44px;
      line-height: 22px;
    }
    i {
      color: rgba(44, 49, 59, 0.25);
    }
    p {
      font-size: 14px;
      color: rgba(44, 49, 59, 0.65);
      line-height: 22px;
      margin-left: 8px;
    }
  }
  &-list {
    width: 100%;
    height: 240px;
    overflow: auto;
    &-item {
      width: 100%;
      height: 60px;
      background: #ffffff;
      border-radius: 2px;
      border: 1px solid rgba(0, 0, 0, 0.15);
      padding: 0 11px 0 20px;
      margin-bottom: 16px;
      cursor: pointer;
      img {
        width: 44px;
        height: 44px;
        border-radius: 50%;
        margin-right: 8px;
      }
      &-title {
        font-size: 14px;
        line-height: 22px;
        color: #2c313b;
        .error{
          font-size: 12px;
          color: #F56C6C;
        }
      }
      &-type {
        font-size: 14px;
        line-height: 22px;
        color: #999999;
      }
      .iconSuccess {
        font-size: 20px;
        color: #6178ff;
        display: none;
      }
      &.active {
        background: rgba(97, 120, 255, 0.05);
        border: 1px solid #6178ff;
        .iconSuccess {
          display: block;
        }
      }
      &.disable {
        //pointer-events: none;
        cursor: not-allowed;
        opacity: 0.3;
      }
    }
  }
}
</style>
