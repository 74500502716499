import MainContent from '@/components/MainContent'
import SearchBar from '@/components/SearchBar'
import MenuItem from '@/components/menu/MenuItem'
import LayoutTable from '@/components/table/LayoutTable'
import PaginateTableNoPage from '@/components/table/PaginateTableNoPage'
import TextSwitch from '@/components/TextSwitch'
import wechatPubFlatAcc from '@/components/wechatPubFlatAcc/index'

export default {
  install(Vue) {
    Vue.component('MainContent', MainContent)
    Vue.component('SearchBar', SearchBar)
    Vue.component('MenuItem', MenuItem)
    Vue.component('LayoutTable', LayoutTable)
    Vue.component('PaginateTableNoPage', PaginateTableNoPage)
    Vue.component('TextSwitch', TextSwitch)
    Vue.component('wechatPubFlatAcc', wechatPubFlatAcc)
  },
}
