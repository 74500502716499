import axios from 'axios'  

class personalApi {
  /**
   * 发送验证码
   * @param {Object} data
   * {
   *  mobile: 手机号
   *  smsmode:  0 .登录、1.注册、2.修改密码、3.绑定手机号、4.解绑手机号
   * }
   */
  getCaptcha(data) {
    let url = `/api/generalUser/login/sms`
    return axios.post(url, data)
  }

  /**
   * 绑定手机号
   * @param {Object} data
   * {
   *  mobile
   *  captcha:  验证码
   * }
   */
  addPhone(data) {
    let url = `/api/generalUser/relation`
    return axios.post(url, data)
  }

  /**
   * 解绑手机号
   * @param {Object} data
   * {
   *  mobile
   *  captcha
   * }
   */
  removePhone(data) {
    let url = `/api/generalUser/unbound`
    return axios.post(url, data)
  }

    /**
   * 修改手机号
   * @param {Object} data
   * {
   *  "captcha": "", // 验证码
   *  "mobile": "", // 手机号
   *  "smsmode": "" // 0 .登录、1.注册、2.修改密码、3.绑定手机号、4.解绑手机号
   * }
   */
     checkMobileCaptcha(data) {
      let url = `/api/generalUser/login/smsCheck`
      return axios.post(url, data)
    }

  /**
   * 修改手机号
   * @param {Object} data
   * {
   *  "captcha": "", // 验证码
   *  "checkCode": "", // 校验码(上一步解绑验证成功短信生成)
   *  "mobile": "", // 手机号
   *  "oldCaptcha": "", // 旧的手机验证码
   *  "oldMobile": "" // 旧的手机号
   * }
   */
   changesMobile(data) {
    let url = `/api/generalUser/changesMobile`
    return axios.post(url, data)
  }

  /**
   * 修改密码
   * @param {Object} data
   * {
   *  mobile
   *  password 密码
   *  captcha
   * }
   */
  changePwd(data) {
    let url = `/api/generalUser/changePwd`
    return axios.post(url, data)
  }

  /**
   * 禁止密码登录
   *
   */
  disablePwd() {
    let url = `/api/generalUser/disablePwd`
    return axios.post(url)
  }


  /**
   * 设置默认企业
   * merchantId
   */
   setDefaultEnterprise(data) {
    let url = `/api/generalUser/central`
    return axios.post(url, data)
  }

  /**
   * 添加新企业
   * authCode
   */
   addMerchant(authCode) {
    let url = `/api/generalUser/addMerchant/${authCode}`
    return axios.post(url)
  }

  /**
   * 解绑我的企业
   * merchantId
   */
   unboundMerchant(data) {
    let url = `/api/generalUser/unboundMerchant`
    return axios.post(url, data)
  }

  /**
   * 切换企业
   * merchantId
   */
   changeMerchant(data) {
    let url = `/api/generalUser/changesMerchant`
    return axios.post(url, data)
  }

}

export default new personalApi()