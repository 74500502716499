import axios from 'axios'

class CustomerJourneyApi {

    /**
     * 校验分层是否删除【false 不能删除】
     */
     checkDeleteTagSlice(id) {
      let url = `/dataApi/merchantTag/checkDeleteTagSlice?tagSliceId=${id}`
      return axios.get(url)
    }
    /**
     * 查询客户-企微账号匹配规则
     */
     queryRule() {
      let url = `/dataApi/wechatRule/queryRule`
      return axios.post(url)
    }
    /**
     * 判断企微账号是否已经被关联
     */
     checkWeChatStatus(id) {
      let url = `/dataApi/wechatRule/checkWeChatStatus`
      return axios.post(url, id)
    }
    /**
     * 保存客户-企微账号匹配规则
     */
     saveRule(data) {
      let url = `/dataApi/wechatRule/saveRule`
      return axios.post(url, data)
    }
    /**
     * 同步客户-企微账号
     */
     syncWechat(data) {
      let url = `/dataApi/wechatRule/syncWechat`
      return axios.post(url)
    }
    /**
     * 手动保存客户-企微账号信息
     */
     saveWeChatCust(data) {
      let url = `/dataApi/wechatRule/saveWeChatCust`
      return axios.post(url, data)
    }
    /**
     * 所有企微客户信息(或搜索)
     */
     allWeChatCust(data) {
      let url = `/dataApi/wechatRule/allWeChatCust`
      return axios.post(url, data)
    }
    /**
     * 删除标签
     */
     deleteMerchantTag(id) {
      let url = `/dataApi/merchantTag/deleteMerchantTag?tagId=${id}`
      return axios.get(url)
    }
    /**
     * 查询标签每个分层详细信息
     */
     getMerchantTagInfoFull(id) {
      let url = `/dataApi/merchantTag/getMerchantTagInfoFull?id=${id}`
      return axios.get(url)
    }
    /**
     * 插入或者更新标签信息2
     */
     insertOrUpdateSliceListRuleInfo(data) {
      let url = `/dataApi/merchantTag/insertOrUpdateSliceListRuleInfo`
      return axios.post(url, data)
    }
    /**
     * 标签分组treeData
     */
     getTagTreeData() {
      let url = `/dataApi/tagCatalogue/getChild?parentId=0`
      return axios.get(url)
    }
    /**
     * 查询标签分层客户统计数量
     */
     merchantTagCustomerCount(id) {
      let url = `/dataApi/merchantTag/merchantTagCustomerCount?tagId=${id}`
      return axios.get(url)
    }
    /**
     * 手动更新客户标签
     */
     refreshCustomerTag(id) {
      let url = `/dataApi/merchantTag/refreshCustomerTag?id=${id}`
      return axios.get(url)
    }
    /**
     * 查询标签基本信息
     */
     getMerchantTagInfo(id) {
      let url = `/dataApi/merchantTag/getMerchantTagInfo?id=${id}`
      return axios.get(url)
    }

  /**
   * 客户群编辑回显
   */
   getCustomerTagGroup(id) {
    let url = `/dataApi/customerTagGroup/getCustomerTagGroup?id=${id}`
    return axios.get(url)
  } 
  /**
   * 客户群预估人数
   */
   expectCusNum(data) {
    let url = `/dataApi/customerTagGroup/expectCusNum`
    return axios.post(url, data)
  } 
 
  /**
   * 切换资源集
   */
   switchPropertyConfigTYpe(id) {
    let url = `/dataApi/propertyConfigType/switchPropertyConfigTYpe?propertyConfigTypeId=${id}`
    return axios.get(url)
  } 
  /**
   * 查询商户可看到的所有属性集
   */
   selectAllConfigType(id) {
    let url = `/dataApi/propertyConfigType/selectAllConfigType`
    return axios.get(url)
  } 
  /**
   * 获取用户此次登录应设置的属性集
   */
  getLoginPropertyConfigType(id) {
    let url = `/dataApi/propertyConfigType/getLoginPropertyConfigType`
    return axios.get(url)
  } 
  /**
   * 删除客群
   */
  delCustomerTagGroup(id) {
    let url = `/dataApi/customerTagGroup/delCustomerTagGroup?id=${id}`
    return axios.get(url)
  } 
  /**
   * 保存客群
   */
  saveCustomerTagGroup(data) {
    let url = `/dataApi/customerTagGroup/saveCustomerTagGroup`
    return axios.post(url, data)
  } 
  /**
   * 客群列表（不分页）
   */
   customerTagGroupList(data) {
    let url = `/dataApi/customerTagGroup/customerTagGroupList`
    return axios.post(url, data)
  } 
  /**
   * 目录标签分层树
   */
  tagCatalogSliceInfoList(data) {
    let url = `/dataApi/customerTagGroup/tagCatalogSliceInfoList`
    return axios.post(url, data)
  }


  // 客户池相关
  //

  /**
  * 客户导入模板
  * 
  */
  importMode() {
    return axios({
        url: `/dataApi/customerPool/downloadCustomerExcelModel`,
        method: 'get',
        responseType: 'blob'
    })
  }

  /**
  * 客户导入
  * 
  * data: {
  *   type
  *   uploadFile
  * }
  */
  importCustomer(data) {
    let url = '/dataApi/customerPool/insertFileTask'
    return axios.post(url, data)
  }

  /**
  * 查询所有属性 所有列目
  * 
  * 属性名
  * @param {String} name
  */
  getAllAttr(name) {
    let url = `/dataApi/property/getAllProperty?name=${name}`
    return axios.get(url)
  }

  /**
  * 导出失败/重复文件
  * 
  * 文件名
  * @param {String} key
  */
   downLoadMode(key) {
    return axios({
        url: `/dataApi/fileUpload/downLoad?downLoadKey=${key}`,
        method: 'get',
        responseType: 'blob'
    })
  }

  /**
  * 客户池删除客户
  * 
  * 客户Id
  * @param {Number} id
  */
  delCustomer(id) {
    let url =`/dataApi/customerPool/deleteCustomer?id=${id}`
    return axios.get(url)
  }

  /**
  * 获取客群名称和分层名称
  * 
  * 客户id
  * @param {Number} id
  */
  customerInfo(id) {
    let url = `/dataApi/customerInfo/getGroupSliceInfo?id=${id}`
    return axios.get(url)
  }

  /**
  * 获取客户属性和值
  *
  * 客户id
  * @param {Number} id
  */
  getCustomerAttr(id) {
    let url = `/dataApi/customerInfo/getProperties?id=${id}`
    return axios.get(url)
  }

  /**
  * 修改客户属性
  *
  * data {
  *   customerId 客户id
  *   propertyId 修改的属性id
  *   propertyValue 修改的值
  * }
  * 
  */
     customerAttrChanges(data) {
      let url = `/dataApi/customerInfo/modifyProperties`
      return axios.post(url, data)
    }

}

export default new CustomerJourneyApi()
