/*
 * @Author: liuxiang
 * @Date: 2021-02-22 14:13:59
 * @LastEditors: liuxiang
 * @LastEditTime: 2021-02-25 10:18:43
 * @Description: file content
 */
export default [{
  path: '/content-archive',
  name: 'ContentArchive',
  meta: {
    title: '会话存档',
  },
  component: () => import('@/views/contentArchive/Index'),
  children: [{
      path: 'base-set',
      name: 'BaseSet',
      meta: {
        title: '基础设置',
      },
      component: () => import('@/views/contentArchive/baseSet/index'),
    },
    {
      path: 'words-record',
      name: 'WordsRecord',
      meta: {
        title: '敏感词触发',
      },
      component: () => import('@/views/contentArchive/sensitiveWords/wordsRecord'),
    },
    {
      path: 'words-record-set',
      name: 'WordsRecordSet',
      meta: {
        title: '敏感词设置',
      },
      component: () => import('@/views/contentArchive/sensitiveWords/wordsRecordSet'),
    },
    {
      path: 'private-chat',
      name: 'PrivateChat',
      meta: {
        title: '私聊检索',
      },
      component: () => import('@/views/contentArchive/chatSearch/privateChat'),
    },
    {
      path: 'group-chat',
      name: 'GroupChat',
      meta: {
        title: '群聊检索',
      },
      component: () => import('@/views/contentArchive/chatSearch/groupChat'),
    },
  ]
}]