

	
	/** ***********************************
	 *     author: 1580490845@qq.com      *
	 *     date: 2021/12/28               *
	 *     desc: 关于数字的自定义指令       *
	 **================================= **/
	/**
	 * 示例
	 * 
	 * isNumber: false 数量相关数字反之true
	 * 
	 * <el-input v-model="form.yzm" v-number="{maxLong: 6, prop: form, propName: 'yzm'}" placeholder="请输入验证码"></el-input>
	 * 
	 * <el-input v-model="form.num" v-number="{maxNum: 99, minNum:1, prop: form, propName: 'num'}" placeholder="请输入数量1-99"></el-input>
	 * 
	 * <el-input v-model="form.num" v-number="{lastPoint: 2, prop: form, propName: 'num'}" placeholder="请输入数量，两位小数"></el-input>
	 * 
	 */

function MaxNum(prop, propName, e, num) {
	if(num < Number(e.target.value)) (prop[propName] = e.target.value = String(num))
}

function MinNum(prop, propName, e, num) {
	if(num > Number(e.target.value)) (prop[propName] = e.target.value = num)
}

function MaxLong(prop, propName, e, num) {
	if(num < String(e.target.value).length) (prop[propName] = e.target.value = String(e.target.value).substring(0, num))
}

function MinLong(prop, propName, e, num) {
	if(num > String(e.target.value).length)	(prop[propName] = e.target.value = String(e.target.value).substring(0, num))
}

function Point(prop, propName, e, lastPoint, eVal) {
	// 小数点前面的数字
	const fristNumber = eVal.substring(0, eVal.indexOf('.') + 1)

	// 小数点后面的数字
	const lastNumber = String(eVal).substring(String(eVal).indexOf('.') + 1)

	if(fristNumber && (lastNumber.length > lastPoint)) {
		prop[propName] = e.target.value =  fristNumber + lastNumber.substring(0, lastPoint)
	}

}

import Vue from 'vue'
const number = Vue.directive('number', {
	inserted(el, {value}) {
			
			el.oninput = function(e) {

			let eVal = e.target.value;
			let nowVal = eVal.substring(eVal.length - 1)

			/**
			 * value
			 * @private {Object} 传入的值
			 * @param {Object} prop 改变值的对象
			 * @param {String} propName 改变值的字段名
			 * @param {Number} lastPoint 保留小数点后几位
			 * @param {Number} maxNum 最大输入
			 * @param {Number} minNum 最小输入
			 * @param {Number} maxLong 最大长度
			 * @param {Number} minLong 最小长度
			*/
			let {isNumber, prop, propName, minNum, maxNum, maxLong, minLong, lastPoint} = value

			// 不能输入 如 00.11
			if (!isNumber) {
				if(nowVal === '0' && (eVal[0] == '0' && eVal.length > 1)) {
					prop[propName] = e.target.value = eVal.substring(1, eVal.length)
					return;
				}
			}

			// 输入的是点或者数字允许进入规则校验 反之删除
			if (!lastPoint && nowVal === '.') { // 无小数位数 .删除
				prop[propName] = e.target.value = eVal.substring(0, eVal.length - 1)
			}

			if((nowVal === '.' || !isNaN(nowVal)) && !isNaN(eVal)) {

				if(minNum !== undefined && MinNum(prop, propName, e, minNum)) return;

				if(maxNum !== undefined && MaxNum(prop, propName, e, maxNum)) return;

				if(minLong !== undefined && MinLong(prop, propName, e, minLong)) return;

				if(maxLong !== undefined && MaxLong(prop, propName, e, maxLong)) return;

				lastPoint !== undefined && Point(prop, propName, e, lastPoint, eVal)

			} else {

				prop[propName] = e.target.value = eVal.substring(0, eVal.length - 1)

			}
		}
	}
})

export default number