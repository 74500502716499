/*
 * @Author: liuxiang
 * @Date: 2021-04-06 16:18:35
 * @LastEditors: liuxiang
 * @LastEditTime: 2021-06-07 10:14:15
 * @Description: file content
 */
// 仓库：用来存放数据，可以直接获取里面的对象，但不能直接修改数据，需通过mutations提交
const state = {
  // isTestLogin: true,
  token: '',
  userInfo: {},
  merchantList: [],
  authRoutes: [],
  menuList: [],
  midGlobal: null,
  onceEndTime: null,
  customerJourneyAttr: {} // 当前营销旅程属性集
}
// 计算属性：对仓库里的数据进行过滤，返回想要的数据
const getters = {
  clientBaseUrl: state=>{
    return process.env.VUE_APP_BASE_URL + `/workwxfront/#/merchant/${state.userInfo.corpId}`
  },
  workWxBaseUrl: state =>{
    return process.env.VUE_APP_BASE_URL + `/workwxfront/#/`
  }
}
// 提交数据：同步commit方式提交，修改仓库里的数据
const mutations = {
  setCustomerJourneyAttr(state, customerJourneyAttr) {
    state.customerJourneyAttr = customerJourneyAttr
  },
  setToken(state, payload) {
    state.token = payload
  },
  setUserInfo(state, payload) {
    state.userInfo = payload
  },
  setMerchantList(state, payload) {
    state.merchantList = payload
  },
  setAuthRoutes(state, payload) {
    state.authRoutes = payload
  },
  setMenuList(state, payload) {
    state.menuList = payload
  },
  SETMIDGLOBAL(state, payload) {
    state.midGlobal = payload;
  },
  SETONCEENDTIME(state, payload) {
    state.onceEndTime = payload;
  }
}

// 提交数据：异步获取数据，提交mutations方法修改数据
const actions = {}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
