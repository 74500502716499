<!--
 * @Author: lcl
 * @LastEditors: lcl
-->
<template>
  <div id="app" @click="handleClickVue">
      <keep-alive>
          <router-view v-if="$route.meta.keepAlive"/>
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive"/>
<!--      <router-view v-if="isRouterAlive"/>-->
  </div>
</template>

<script>
  import store from '@/store'
  import HomeLogin from '@/lib/HomeLogin.js'

  export default {
    name: 'App',
    provide () { // 注入全局reload
      return {
        reload: this.reload
      }
    },
    data() {
      return {
        isRouterAlive: true
      }
    },
    created() {
      // // 在页面加载时读取localStorage里的状态
      // if (localStorage.getItem("Store") ) {
      //   this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(localStorage.getItem("Store"))))
      // }
      // // 在页面刷新时将vuex里的信息保存到localStorage里
      // window.addEventListener("beforeunload", ()=> {
      //   if(this.$store.state.user.token) {
      //     localStorage.setItem("Store", JSON.stringify(this.$store.state));
      //   }
      // })
    },
    methods: {
      reload() {
        this.isRouterAlive = false
        this.$nextTick(() => {
          this.isRouterAlive = true
        })
      },
      handleClickVue() {
        const router = this.$router;
        const token  = this.$store.state.user.token;
        const currentTime = new Date().getTime();
        const onceEndTime = this.$store.state.user.onceEndTime;

        if (currentTime - onceEndTime > 3600000) { // 3600000 = 1小时 时间戳
          if (token != '') {  // 已登录状态
            store.commit('user/setToken', ''); // 清空token
            this.$confirm('因您长时间未操作已被登出，请重新扫码登录', '提示', {
              confirmButtonText: '重新登录',
              cancelButtonText: '取消',
              type: 'warning',
              center: true
            }).then(() => {
              // if(this.$store.state.user.isTestLogin) {
              //   router.replace(
              //     '/login?redirect=' + encodeURIComponent(router.currentRoute.fullPath)
              //   )
              // } else {
              window.location.replace(HomeLogin)
              // }
              localStorage.clear();
            }).catch(() => {
              // if(this.$store.state.user.isTestLogin) {
              //   router.replace(
              //     '/login?redirect=' + encodeURIComponent(router.currentRoute.fullPath)
              //   )
              // } else {
              window.location.replace(HomeLogin)
              // }
              localStorage.clear();
            });
          }
        }
      }
    }
  }
</script>

<style lang="scss">
@import "@/assets/styles/index"
</style>
