var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"flex-contain flex-main-justify wechatAccount"},[_vm._v(" 公众号："),_c('img',{attrs:{"src":_vm.pubAccDefault.headImg ||
          ' //static.lianhaikeji.com/images/20210519/06f835f8181f45508ec79fae6511138b.png',"alt":""}}),_vm._v(_vm._s(_vm.pubAccDefault.mpName ? _vm.pubAccDefault.mpName : "暂未设置")+" "),(_vm.setStatus)?_c('i',{staticClass:"icon el-icon-setting",on:{"click":function($event){_vm.dialogVisible = true}}}):_vm._e()]),_c('el-dialog',{staticClass:"create-dialog",attrs:{"title":"设置","visible":_vm.dialogVisible,"width":"460px"},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('div',{staticClass:"wechatSet"},[_c('div',{staticClass:"wechatSet-title"},[_vm._v("从已授权的公众号选择：")]),_c('div',{staticClass:"wechatSet-tip flex-contain"},[_c('div',{staticClass:"icon"},[_c('i',{staticClass:"el-icon-warning"})]),_c('p',{staticClass:"flex-1"},[_vm._v(" 请选择满足：1. 已认证服务号；2. 绑定在企业微信开放平台的公众号。 ")])]),_c('div',{staticClass:"wechatSet-list"},_vm._l((_vm.pubFlatAccList),function(item,index){return _c('div',{key:index,staticClass:"wechatSet-list-item flex-contain",class:{
              disable:
                item.serviceType != 2 ||
                item.verifyType == -1 ||
                item.verifyType == 1 || !_vm.isHave24(item.funcList),
              active: index == _vm.pubFlatAccIndex,
            },on:{"click":function($event){item.serviceType != 2 ||
              item.verifyType == -1 ||
              item.verifyType == 1 || !_vm.isHave24(item.funcList)
                ? ''
                : (_vm.pubFlatAccIndex = index)}}},[_c('img',{attrs:{"src":item.headImg ||
                '//oss.zzkj8888.com/images/20210519/06f835f8181f45508ec79fae6511138b.png',"alt":""}}),_c('div',{staticClass:"flex-1"},[_c('div',{staticClass:"wechatSet-list-item-title"},[_vm._v(_vm._s(item.mpName)+" "),(!_vm.isHave24(item.funcList))?_c('span',{staticClass:"error"},[_vm._v("未授权开放平台账号管理权限")]):_vm._e()]),_c('div',{staticClass:"wechatSet-list-item-type flex-contain"},[(item.serviceType == 2)?[_vm._v(" 服务号 ")]:[_vm._v(" 订阅号 ")],(item.verifyType == -1 || item.verifyType == 1)?_c('div',{staticClass:"ml-10"},[_c('el-tag',{attrs:{"type":"danger","size":"mini"}},[_vm._v("未认证")])],1):_c('div',{staticClass:"ml-10"},[_c('el-tag',{attrs:{"type":"success","size":"mini"}},[_vm._v("已认证")])],1)],2)]),_c('div',{staticClass:"iconSuccess"},[_c('i',{staticClass:"el-icon-success"})])])}),0)]),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.dialogVisible = false}}},[_vm._v("取消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.setDefaultPubAcc}},[_vm._v("确定")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }