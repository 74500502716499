export default [
  {
    path: '/rpa',
    name: 'RPA',
    meta: {
      title: 'RPA模块',
    },
    component: ()=>import('@/views/rpa/Index'),
    children: [
      {
        path: 'bot',
        meta: {
          title: 'Bot管理',
        },
        component: () => import('@/views/rpa/bot/index'),
      },
    ],
  },
]
