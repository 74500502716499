/*
 * @Author: liuxiang
 * @Date: 2021-04-06 16:18:35
 * @LastEditors: lcl
 * @LastEditTime: 2022-03-07 18:44:37
 * @Description: file content
 */
import axios from 'axios'
import router from '@/router'
import store from '@/store'
import HomeLogin from '@/lib/HomeLogin.js'

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL

// request拦截器
axios.interceptors.request.use(
  (config) => {
    const token = store.state.user.token
    if (token) {
      config.headers.scrm_token = token
    }
    config.headers['X-Requested-With'] = 'XMLHttpRequest'
    
    return config
  },
  (error) => {
    return Promise.resolve({
      status: 500,
      error: '请求失败,请检查网络:' + error,
    })
  }
)

// response拦截器
axios.interceptors.response.use(
  (response) => {
    store.commit('user/SETONCEENDTIME', new Date().getTime());  // 记录最新发送请求的时间戳
    const data = response.data
    if (!data) {
      console.error('返回数据异常')
    }
    if (data.status === 401) {
      // debugger
      store.commit('user/setToken', '')
      
      // if(store.state.user.isTestLogin) {
      //   router.replace(
      //     '/login?redirect=' + encodeURIComponent(router.currentRoute.fullPath)
      //   )
      // } else {
        window.location.replace(HomeLogin)
      // }
      console.log('401=====================未登录')
      return data
    }
    // 接口 404 储存时间戳  如果时间戳在 一天前 刷新页面
    if (data.status === 404) {
      if (localStorage.getItem('refreshPage')) {
        // 超过一天
        if (
          Number(localStorage.getItem('refreshPage')) - +new Date() >
          24 * 60 * 60 * 1000
        ) {
          location.reload()
          localStorage.setItem('refreshPage', (+new Date()).toString())
        }
      } else {
        location.reload()
        localStorage.setItem('refreshPage', (+new Date()).toString())
      }
    }
    // eslint-disable-next-line no-empty
    if (data.status !== 200) {
    }
    return data
  },
  (error) => {
    if (error && error.response) {
      if (error.response.status === 403) {
        store.commit('user/setToken', '')
        // if(this.$store.state.user.isTestLogin) {
        //   router.replace(
        //     '/login?redirect=' + encodeURIComponent(router.currentRoute.fullPath)
        //   )
        // } else {
        window.location.replace(HomeLogin)
        // }

        return Promise.resolve({
          status: 500,
          error: '重新登录',
        })
      }
      if (error.response.status === 404) {
        return Promise.resolve({
          status: 500,
          error: '404接口路径不存在',
        })
      }
    }
    return Promise.resolve({
      status: 500,
      error: '请求处理失败',
    })
  }
)

export default {
  install(Vue) {
    Vue.prototype.$get = axios.get
    Vue.prototype.$post = axios.post
    Vue.prototype.$delete = axios.delete
  }
}
