/*
 * @Author: liuxiang
 * @Date: 2021-01-22 13:48:40
 * @LastEditors: liuxiang
 * @LastEditTime: 2021-07-06 10:15:52
 * @Description: file content
 */
import Vue from 'vue'
import router from './router'
import store from './store'
import Request from './lib/Request'
import element from '@/plugins/element'
Vue.prototype.$ELEMENT = { size: 'mini', zIndex: 3000 };
Vue.use(element);
import App from './App.vue'
import permission from '@/directive/permission/index'
import AuthPlugin from '@/plugins/AuthPlugin'
import PublicComponents from './components'
import '@/lib/AddQuery' // 设置全局用 query
import Clipboard from 'v-clipboard'

import infiniteScroll from "vue-infinite-scroll";
 Vue.use(infiniteScroll);

// 全局过滤器
import * as filters from '@/filters'
import Mixin from '@/mixins';
// 自定义指令number
import '@/directive/number.js'
import 'element-ui/packages/theme-chalk/src/index.scss'
Vue.mixin(Mixin);
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key])
})
Vue.use(Clipboard)

Vue.use(Request)
Vue.use(PublicComponents)
Vue.use(AuthPlugin)
Vue.use(permission)

Vue.prototype.$isProd = (process.env.NODE_ENV === 'production')

// 虚拟验证
import FalseVerificationC from './components/FalseVerification/index.js';
Vue.use(FalseVerificationC)

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
