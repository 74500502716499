export default [
    {
      path: '/messaging',
      name: 'MessagingManage',
      meta: {
        title: '群发消息',
      },
      component: ()=>import('@/views/messaging/Index'),
      children: [
        {
          path: 'new-sending',
          name: 'newSending',
          meta: {
            title: '新增群发',
          },
          component: () => import('@/views/messaging/newSending/newIndex'),
        },
        {
          path: 'sending-list',
          name: 'sendingList',
          meta: {
            title: '群发列表',
            keepAlive: true,
          },
          component: () => import('@/views/messaging/sendingList/Index'),
        },
        {
          path: 'detail-dialog',
          name: 'detailDialog',
          meta: {
            title: '群发列表',
          },
          component: () => import('@/views/messaging/sendingList/detailDialog.vue'),
        },
      ],
    },
  ]
