<template>
  <div class="content" :class="{ simple: simple, 'inline-content': inlineContent }">
    <div v-if="title || back" class="content-header">
      <el-button
        v-if="back"
        circle
        class="content-back"
        icon="el-icon-back"
        @click="handelBack"
      ></el-button>
      <div class="content-title">
        {{ title }}
        <span class="sub-title">
          <slot name="sub-title"></slot>
        </span>        
      </div>
      <slot name="content"></slot>
    </div>
    <slot name="tab-bar"></slot>
    <slot name="search-bar"></slot>
    <div v-if="content" class="content-slot" :class="{'content-slot-bg': defaultContentSlot}">
      <slot></slot>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      // false 背景颜色透明，true 背景颜色默认白色
      defaultContentSlot: {
        type: Boolean,
        default: ()=>{
          return true
        },
      },
      inlineContent: {
        type: Boolean,
        default: false,
      },
      content: {
        type: Boolean,
        default: true,
      },
      back: {
        type: Boolean,
        default: false,
      },
      backFunc: {
        type: Boolean,
        default: false,
      },
      title: {
        type: String,
        default: "",
      },
      simple: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        showBack: this.back != undefined,
      };
    },
    methods: {
      handelBack() {
        if(this.backFunc) {
          this.$emit('back')
        }else {
          this.$router.back();
        }
        
      },
    },
  };
</script>

<style lang="scss" scoped>
.content {
  margin: 20px;
  padding: 10px;
  min-height: 300px;
  // box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);
  // background: #fff;
  border-radius: 3px;
  // overflow: hidden;

  .inline-content {
    margin: 0;
    padding: 0;
  }

  .content-header {
    position: relative;
    margin-bottom: 16px;
    //padding: 5px 0 10px 10px;
    height: 32px;
    line-height: 32px;
    font-size: 20px;

    .content-title {
      display: inline-block;
      vertical-align: middle;
      font-weight: 700;

      .sub-title {
        font-weight: 400;
        font-size: 12px;
        color: #909399;
      }
    }

    .content-back {
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px;
    }
  }
  .search-bar {
    background: #ffffff;
    border-radius: 2px;
    padding: 24px 24px 10px;
    margin-top: 16px;
  }
  .content-slot-bg{
    background: #ffffff;
    border-radius: 2px;
    padding: 16px 24px;
    overflow: auto;
    min-height: 500px;
  }
}
.simple.content {
  box-shadow: none;
  background: none;
  .content-header {
    border: none;
    padding: 5px 0 10px 0;
    &::before {
      display: none;
    }
    .content-back {
      margin: 0 10px 0 0;
    }
  }
}

/deep/.el-tabs__header {
  margin: 0 0 24px;

  .el-tabs__item {
    height: 36px;
    line-height: 36px;
  }
}
/deep/.el-tabs__nav-wrap {
  &::after {
    background-color: transparent;
  }
}
</style>
