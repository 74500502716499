import store from '@/store'

export default {
  update(el, binding, vnode) {
    const { value } = binding
    if (value) {
      if(store.state.user.authRoutes.indexOf(value)<0){
        el.remove()
      }
    } else {
      throw new Error(`need roles! Like v-permission="'edit'"`)
    }
  }
}
