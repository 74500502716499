<template>
  <div class="FalseVerification">
    <el-input v-model="value" placeholder="请输入内容" v-if="type === 'String'" style="display: none;"></el-input>
    <el-checkbox-group v-model="value" v-if="type === 'Array'" style="display: none;">
      <el-checkbox label="复选框 A"></el-checkbox>
      <el-checkbox label="复选框 B"></el-checkbox>
    </el-checkbox-group>
  </div>
</template>

<script>
export default {
  name: 'FalseVerification',
  props: {
    type: {
      type: String,
      default: () => {
        return '';
      }
    },
    value: {
      type: [ String, Array, Boolean, Object ], // 后续可添加各种类型
      default: false
    }
  },
  data() {
    return {

    }
  },
  mounted() {

  },
  methods: {

  }
}
</script>
<style lang="scss" scoped>
.FalseVerification {

}
</style>