<template>
  <div class="paginate-table">
    <el-table
      ref="elTable"
      v-loading="loading"
      :row-class-name="rowClassName"
      :data="data"
      :max-height="maxTableHeight"
      :header-cell-style="{
        background: 'rgba(132, 138, 174, 0.06)',
      }"
      :stripe="stripe"
      :border="border"
      :row-key="rowKey"
      :tree-props="treeProps"
      :highlight-current-row="highlightCurrentRow"
      @expand-change="expandChange"
      @selection-change="handleSelectionChange"
      @current-change="handleTableCurrentChange"
      @select="handleSelect"
      @row-click="handleRowClick"
    >
      <slot></slot>
    </el-table>
    <div v-if="showPager" class="paginate-pager">
      <el-pagination
        :current-page="pager.current"
        :page-sizes="pager.pageSizes"
        :page-size="pager.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pager.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
    <slot name="pagination"></slot>
  </div>
</template>

<script>
  export default {
    name: "LayoutTable",
    props: {
      rowKey: {
        require: false,
        type: String,
        default: ()=>{
          return ""
        },
      },
      treeProps: {
        require: false,
        type: Object,
        default: ()=>{
          return {}
        },
      },
      border: {
        require: false,
        type: Boolean,
        default: ()=>{
          return false
        },
      },
      highlightCurrentRow: {
        require: false,
        type: Boolean,
        default: ()=>{
          return false
        },
      },
      stripe: {
        require: false,
        type: Boolean,
        default: ()=>{
          return true
        },
      },
      listColumName: {
        require: false,
        type: String,
        default: "",
      },
      param: {
        require: false,
        type: Object,
        default: () => {
          return {};
        },
      },
      debounce: {
        require: false,
        type: Number,
        default: 500,
      },
      url: {
        require: false,
        type: String,
        default: "",
      },
      hideInit: {
        require: false,
        type: Boolean,
        default: false,
      },
      hideWatch: {
        require: false,
        type: Boolean,
        default: false,
      },
      showPager: {
        require: false,
        type: Boolean,
        default: true,
      },
      noPage: { // 是否为不分页
        require: false,
        type: Boolean,
        default: false,
      },
      singleData: {  // 是否为单条非数组数据
        require: false,
        type: Boolean,
        default: false,
      },
      // eslint-disable-next-line vue/require-default-prop
      filter: {
        type: Function,
        require: false,
      },
      disableAutoHeight: {
        require: false,
        type: Boolean,
        default: false,
      },
      rowClassName: {
        type: [Function, String],
        require: false,
        default: "",
      },
      dataList: {
        type: Array,
        require: false,
        default: () => {
          return [];
        },
      },
      isLoading: { // 是否显示loading效果
        type: Boolean,
        default: true
      },
      size: { // 每页多少条数据
        type: Number,
        default: 15
      }
    },
    data() {
      return {
        tableData: [], // 所有列表
        maxTableHeight: 500,
        loading: false,
        list: [],
        pager: null,
        autoSearchInd: null,
      };
    },
    computed: {
      data() {
        if (this.filter) {
          return this.filter(this.list);
        }
        return this.list;
      },
    },
    watch: {
      data: {
        deep: true,
        handler() {
          this.autoHeight();
        },
      },
      url: {
        deep: false,
        handler() {
          this.research();
        },
      },
      param: {
        deep: true,
        handler() {
          if (!this.hideWatch) {
            if (this.autoSearchInd) {
              clearTimeout(this.autoSearchInd);
            }
            this.autoSearchInd = setTimeout(this.research, this.debounce || 500);
          }
        },
      },
    },
    created() {
      if (this.showPager) {
        let size = this.size || 15
        let pageSizes = [15, 30, 50]
        if (this.size===10) {
          pageSizes = [10, 20, 40]
        }
        
        this.pager = {
          current: 1,
          size,
          pageSizes
        }
      }
      this.setStoragePage();
      window.addEventListener("resize", this.autoHeight);
      if (!this.hideInit) {
        this.search();
      }
      if (this.disableAutoHeight) {
        this.maxTableHeight = null;
      } else {
        this.maxTableHeight = 500;
      }
    },
    beforeDestroy() {
      window.removeEventListener("resize", this.autoHeight);
    },
    methods: {
      autoHeight() {
        if (this.disableAutoHeight) {
          return;
        }
        this.$nextTick(() => {
          let pageHeight = this.$el.parentElement.offsetHeight;
          let bros = this.$el.parentElement.childNodes;
          let brosHeight = 0;
          for (let i = 0; i < bros.length; i++) {
            if (bros[i] !== this.$el) {
              if (bros[i].offsetHeight) {
                brosHeight = brosHeight + bros[i].offsetHeight;
              }
            }
          }
          this.$el.style.height = pageHeight - brosHeight - 20 + "px";
          // if (this.showPager && this.pager) {
          if (this.$el.querySelector(".paginate-pager")) {
            this.maxTableHeight =
              this.$el.offsetHeight -
              this.$el.querySelector(".paginate-pager").offsetHeight;
          } else {
            this.maxTableHeight = this.$el.offsetHeight;
          }
        });
      },
      handleSizeChange(s) {
        this.pager.size = s;
        this.search();
        this.$emit('handleSizeChange', s);
      },
      handleCurrentChange(page) {
        this.pager.current = page
        this.search('clickPagination');
        this.$emit('handleCurrentChange', page);
      },
      deleteRow(row) {
        let ind = this.list.indexOf(row);
        if (ind > -1) {
          this.list.splice(ind, 1);
        }
      },
      addRow(row) {
        this.list.push(row);
      },
      async research() {
        // if (this.pager) {
        //   this.pager.current = 1;
        // }
        this.search();
      },
      // 计算属性对数据进行处理
      frontEndPageChange() {
        let start = (this.pager.current - 1) * this.pager.size;
        if (start >= this.tableData.length) start = 0;
        let end = this.pager.current * this.pager.size;
        if (end >= this.tableData.length) end = this.tableData.length;
        this.list = this.tableData.slice(start, end);
      },
      async search(type = null) {
        if (!this.url) {
          this.tableData = this.dataList || [];
          this.frontEndPageChange()
          this.pager.total = this.tableData.length
          return;
        }

        // 查询，重置，去第一页
        if (this.pager && type !== 'clickPagination') {
          this.pager.current = 1
        }
        
        this.loading = this.isLoading;
        let form = Object.assign(this.param || {}, this.pager);

        let rs = await this.$post(this.url, form);
        if (rs.status === 200) {
          let list = []
          if(this.noPage){
            list = rs.payload
            if(this.singleData){
              list = [rs.payload]
            }
          }else{
            list = this.listColumName
              ? rs.payload[this.listColumName]
              : rs.payload.records;
          }
          // 添加loading属性
          list && list.forEach((item) => {
            let originData = Object.assign({}, item);
            item.originData = originData;
            item.loading = false;
          });

          this.list = list;
          if (this.showPager) {
            this.pager.current = rs.payload.current || 1;
            this.pager.size = rs.payload.size || 30;
            this.pager.total = rs.payload.total || 0;
          }
        } else {
          this.$message.error(rs.error || "列表获取失败");
        }
        this.loading = false;
        this.$emit("loaded", this.list, rs);
      },
      expandChange(row, expandedRows) {
        this.$emit("expand-change", row, expandedRows);
      },
      handleSelectionChange(val) {
        this.$emit("selection-change", val);
      },
      handleTableCurrentChange(val) {
        this.$emit("current-change", val);
      },
      handleSelect(selection, row){
        this.$emit("select", selection, row);
      },
      handleRowClick(val) {
        this.$emit("row-click", val);
      },
      setStoragePage() {
        var storage = window.localStorage;
        var currentPage = storage.getItem("channelPage");
        if (currentPage) {
          this.pager.current = parseInt(currentPage);
          storage.removeItem("channelPage");
        }
      },
    },
  };
</script>

<style scoped lang="scss">
.paginate-table {
  // overflow: auto;
  /deep/ .el-table {
    // width: 99% !important;
    font-size: 14px;

    th {
      height: 53px;
    }
  }
}
.paginate-pager {
  padding: 10px 0;
}
/deep/.el-pagination {
  span, button, .el-pager, li, input, .el-input {
    font-size: 14px;
    line-height: 32px;
    height: 32px;
  }
}
/deep/ .el-checkbox__inner{
  border: 1px solid #eaeaea;
}
</style>
