/*
 * @Author: liuxiang
 * @Date: 2021-01-22 13:48:40
 * @LastEditors: liuxiang
 * @LastEditTime: 2021-07-26 13:59:36
 * @Description: file content
 */
import axios from 'axios'

class CommonApi {
  // 上传图片
  uploadImage(data, config) {
    let url = `/api/common/uploadImg`
    return axios.post(url, data, config)
  }
  // 用部门id获取部门下所有员工
  getStaffByDepartment(id) {
    let url = `/api/common/department/${id}/staff`
    return axios.get(url)
  }
  // 上传图片oss
  uploadImageOss(data, config) {
    let url = `/api/file/upload`
    return axios.post(url, data, config)
  }
  // 校验文件上传
  validateDomainUpload(data, config) {
    let url = `/api/validateDomain/upload`
    return axios.post(url, data, config)
  }
  // 解析图片 上传二维码转换为URL
  imgChangeIntoUrl(data, config) {
    let url = `/api/lockQrCode/analyzingQrCode`
    return axios.post(url, data, config)
  }

  /**
   * 获取登录者有权限的模块
   */
  loadRouter() {
    let url = `/api/common/loadRouter`
    return axios.get(url)
  }

  /**
   * 获取微信SDK配置
   * @param {String} link 页面链接
   */
  wechatConfig(link) {
    let url = `/api/common/js/config`
    return axios.post(url, {
      url: link
    })
  }

  /**
   * 获取标签list 分组结构
   */
  queryTagList() {
    let url = `/api/common/tag/list`
    return axios.get(url)
  }

  /**
   * 获取组织架构tree
   */
  queryOrgTree(isFollowUser) {
    let url = `/api/common/doc?isFollowUser=${isFollowUser}`
    return axios.get(url)
  }

  /**
   * 查询欢迎语素材列表
   * @param {String} data
   * {
   *  type 欢迎语类型 1-活码欢迎语 2-部门员工欢迎语 3-客户
   *  keyword 欢迎语关键词
   * }
   */
  queryWelcomeTplList(data) {
    let url = `/api/common/welcomeTemplate/list`
    return axios.post(url, data)
  }

  /**
   * 获取当前登录账户信息
   */
   getAccountInfo() {
    let url = `/api/common/getAccountInfo`
    return axios.get(url)
  }

  /**
   * 登录改造后新的
   * 获取当前登录账户信息
   */
  getUserInfo() {
    let url = `/api/generalUser/getGeneralUserInfo`
    return axios.get(url)
  }
  
  /**
  * @description: 查询部门树
  * @param {*}
  * @return {*}
  */
 queryCorpTree() {
   let url = `api/depart/query/corpTree`
   return axios.get(url)
  }
  /**
  * @description: 查询我的企业
  * @param {*}
  * @return {*}
  */
  getMerchantList() {
    let url = `/api/generalUser/getMerchantList`
    return axios.post(url)
  }
}
export default new CommonApi()