/*
 * @Author: liuxiang
 * @Date: 2021-04-06 16:18:35
 * @LastEditors: liuxiang
 * @LastEditTime: 2021-07-22 09:20:24
 * @Description: file content
 */
export default [
  {
    path: '/activity',
    name: 'Activity',
    meta: {
      title: '活动管理',
    },
    component: ()=>import('@/views/activity/Index'),
    children: [
      {
        path: 'fission',
        meta: {
          title: '裂变活动',
          keepAlive: true,
        },
        component: () => import('@/views/activity/fission/index'),
      },
      {
        path: 'fission-add',
        meta: {
          title: '裂变活动',
        },
        component: () => import('@/views/activity/fission/add'),
      },
      {
        path: 'fission-detail/:id',
        meta: {
          title: '裂变详情',
        },
        component: () => import('@/views/activity/fission/detail'),
      },
      {
        path: 'group-fission',
        meta: {
          title: '群裂变',
          keepAlive: true,
        },
        component: () => import('@/views/activity/groupFission/index'),
      },
      {
        path: 'group-fission-add',
        meta: {
          title: '群裂变',
        },
        component: () => import('@/views/activity/groupFission/add'),
      },
      {
        path: 'group-fission-detail/:id',
        meta: {
          title: '裂变详情',
        },
        component: () => import('@/views/activity/groupFission/detail'),
      },
      {
        path: 'group-fission-inviteDetail/:id',
        meta: {
          title: '邀请详情',
        },
        component: () => import('@/views/activity/groupFission/inviteDetail'),
      },
      {
        path: 'interactive-radar',
        meta: {
          title: '互动雷达',
          keepAlive: true,
        },
        component: () => import('@/views/activity/interactiveRadar/index'),
      },
      {
        path: 'interactive-add',
        meta: {
          title: '新建互动雷达',
        },
        component: () => import('@/views/activity/interactiveRadar/add'),
      },
      {
        path: 'interactive-detail/:id',
        meta: {
          title: '雷达详情',
        },
        component: () => import('@/views/activity/interactiveRadar/detail'),
      },
      {
        path: 'interactive-click-detail/:id',
        meta: {
          title: '雷达详情',
        },
        component: () => import('@/views/activity/interactiveRadar/clickDetail'),
      },
      {
        path: 'red-packet',
        meta: {
          title: '红包墙',
          keepAlive: true,
        },
        component: () => import('@/views/activity/redPacket/index'),
      },
      {
        path: 'red-audit',
        name: 'redAudit',
        meta: {
          title: '红包墙活动审核',
        },
        component: () => import('@/views/activity/redPacket/audit'),
      },
      {
        path: 'red-record',
        name: 'redRecord',
        meta: {
          title: '红包领取记录',
        },
        component: () => import('@/views/activity/redPacket/record'),
      },
      {
        path: 'red-add',
        name: 'redAdd',
        meta: {
          title: '红包墙',
        },
        component: () => import('@/views/activity/redPacket/add'),
      },
    ],
  },
]
