import moment from 'moment'
/**
 * Number formatting
 * like 10000 => 10k
 * @param {number} num
 * @param {number} digits
 */
export function numberFormatter(num, digits) {
  const si = [
    {
      value: 1e18,
      symbol: 'E',
    },
    {
      value: 1e15,
      symbol: 'P',
    },
    {
      value: 1e12,
      symbol: 'T',
    },
    {
      value: 1e9,
      symbol: 'G',
    },
    {
      value: 1e6,
      symbol: 'M',
    },
    {
      value: 1e3,
      symbol: 'k',
    },
  ]
  for (let i = 0; i < si.length; i++) {
    if (num >= si[i].value) {
      return (
        (num / si[i].value + 0.1)
          .toFixed(digits)
          .replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[i].symbol
      )
    }
  }
  return num.toString()
}

/**
 * 10000 => "10,000"
 * @param {number} num
 */
export function toThousandFilter(num) {
  return (+num || 0)
    .toString()
    .replace(/^-?\d+/g, (m) => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','))
}

/**
 * Upper case first char
 * @param {String} string
 */
export function uppercaseFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

/**
 *
 * @param time
 * @returns {string}
 * @constructor
 */
export function dateFormatter(time) {
  return moment(time).format('YYYY-MM-DD 00:00:00')
}

/**
 *
 * @param time
 * @returns {string}
 * @constructor
 */
export function nowDateFormatter(time) {
  return moment(time).format('YYYY-MM-DD HH:mm:ss')
}

export function fullDayFormatter(time) {
  return moment(time).format('YYYY-MM-DD')
}

export function fullDayCFormatter(time) {
  return moment(time).format('YYYY年MM月DD日')
}

export function dayFormatter(time) {
  return moment(time).format('MM-DD')
}

/**
 *
 * @param time
 * @returns {string}
 * @constructor
 */
export function timeFormatter(time) {
  return moment(time).format('YYYY-MM-DD HH-mm-ss')
}

// 手机号码星号替换
export function mobile(value) {
  if (value && value.length === 11) {
    let start = value.slice(0, 3)
    let end = value.slice(-4)
    return `${start}****${end}`
  } else {
    return value
  }
}

/**
 * 时间格式范围化 例如 1天前 5分钟前
 * @param time
 * @returns {string}
 */
export function dateHumanize(time) {
  if(!time) return '--'
  let m1 = moment(time),
    m2 = moment(),
    du = moment.duration(m1 - m2, 'ms')
  return du.locale('zh-cn').humanize(true)
}

/**
 * @description: 格式化文件大小
 * @param value
 * @return {string}
 */
 export function renderSize(value){
  if(null==value||value==''){
      return "0 Bytes";
  }
  var unitArr = new Array("Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB");
  var index=0;
  var srcsize = parseFloat(value);
  index=Math.floor(Math.log(srcsize)/Math.log(1024));
  var size =srcsize/Math.pow(1024, index);
  size=size.toFixed(2);// 保留的小数位数
  return size+unitArr[index];
}
