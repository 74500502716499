<template>
  <el-container class="home" v-loading="loading">
    <el-header class="header" style="min-width: 1200px">
      <!-- <div class="platform-name" @click="toDashboard"> -->
      <div class="platform-name">
        <img class="icon" src="//oss.zzkj8888.com/images/20210203/logo.png" alt="" />
        掌站·营销宝

        <div v-if="merchantList.length===0" class="corp-span" style="margin-left: 20px">
          {{ userInfo.corpName }}
        </div>

        <div v-else class="merchant-bar">
          <el-dropdown trigger="click">

            <span class="el-dropdown-link">
              {{userInfo.corpName}}
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>

            <div v-if="merchantList.length>0">
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="(item,index) in merchantList" @click.native="changeMerchant(item)" :key="index">
                {{item.corpName}}
              </el-dropdown-item>
            </el-dropdown-menu>
            </div>

          </el-dropdown>
        </div>

      </div>

      <div class="menu">
      </div>

      <div class="toolbar">
        <el-dropdown>
          <span class="el-dropdown-link">
            {{ userInfo.name }}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="toUseInfo"><i class="el-icon-user-solid"></i> 账号信息
            </el-dropdown-item>
            <el-dropdown-item @click.native="exit"><i class="el-icon-switch-button"></i> 退出登录
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </el-header>
    <el-main class="body">
      <div v-if="showSideMenu && globalShowSideMenu" class="side-menu" :class="{ 'side-menu-hide': !showSide }">
        <div class="menu-list">
          <div class="menuItem">
            <div :class="{ active: mid == 0 }" class="menuItem-title" @click="
                onMenuRightClick({
                  type: 'URL',
                  router: '/dashboard',
                  mid: 0,
                })
              ">
              <i class="iconfont icon-shequyunying_Operation"></i>首页
            </div>
          </div>
          <div v-for="(menu, index) in newMenuList" v-show="menu.type != 'HIDDEN'" :key="index" class="menuItem">
            <div class="menuItem-title" :class="{ active: mid == menu.mid }" @click="onMenuRightClick(menu)">
              <i class="iconfont" :class="menu.icon"></i>{{ menu.mname }}
            </div>
            <div class="menuItem-list flex-contain flex-wrap">
              <div v-for="(item, idx) in menu.children" v-show="item.type != 'BUTTON' && item.type != 'HIDDEN'" :key="idx" class="menuItem-list-item" @click="onMenuRightClick(item)">
                <div class="text" :class="{ active: mid == item.mid }">
                  <div v-if="item.moduleVersion=='1'" class="tips">
                    <!-- <img
                      src="http://static.lianhaikeji.com/images/20210609/bcc4801972bc46d2a5043c9f6a915407.png"
                      alt="内测"
                    /> -->
                    <img src="//oss.zzkj8888.com/images/20211220/335e9171db944193931e9b989ceeecd5.png" alt="NEW" />
                  </div>
                  {{ item.mname }}
                </div>
              </div>
            </div>
          </div>
          <!-- <menu-item
            v-for="menu in newMenuList"
            :key="menu.mid"
            :menu="menu"
            :show-side="showSide"
          ></menu-item> -->
        </div>
        <!-- <div class="bottom-icon">
            <div
              class="menu-name"
              :class="{ 'menu-name-hide': !showSide }"
              style="
                text-align: center;
                color: #ffffff;
                line-height: 46px;
                cursor: pointer;
              "
              @click="toggleSide"
            >
              <i class="iconfont iconline-open"></i>
            </div>
          </div> -->
      </div>

      <div id="body-content" ref="body" class="body-content" :class="{
          'body-content-full': !showSideMenu || !globalShowSideMenu,
          'wide-content': !showSide,
        }">
        <router-view></router-view>
      </div>
    </el-main>
  </el-container>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import CommonApi from "@/api/commonApi";
import AuthApi from "@/api/authApi";
import personalApi from '@/api/system/personalApi.js'
import CustomerJourneyApi from '@/api/customerJourney/index'
import HomeLogin from '@/lib/HomeLogin.js'

export default {
  name: "Home",
  inject: ['reload'], // 引入全局reload
  data() {
    const sideMenuCache = sessionStorage.getItem("side_menu_cache");
    let sideMenu = null;
    if (sideMenuCache) {
      sideMenu = JSON.parse(sideMenuCache);
    }
    return {
      authIds: [],
      menuList: [],
      sideMenu: sideMenu || [],
      showSideMenu: true,
      showSide: true,
      content: "",
      mid: -1,
      loading: false
    };
  },

  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
      merchantList: (state) => state.user.merchantList,
      globalShowSideMenu: (state) => state.common.showSideMenu,
      globalMenuList: (state) => state.user.menuList,
      midGlobalGet: (state) => state.user.midGlobal
    }),
    newMenuList() {
      let arr = [];
      this.globalMenuList.map((item) => {
        if (item.children) {
          arr = arr.concat(item.children);
        }
      });
      return arr;
    },
  },
  watch: {
    '$route.fullPath'(val) {
      // 不展示左侧菜单栏
      this.showSideMenu = !this.$route.meta.hideLeftMenu
    },
    midGlobalGet: {
      handler(val) {
        this.mid = val
      },
      deep: true,
    }
  },
  async created() {
    if (this.$store.state.user.midGlobal != null) {
      this.mid = this.$store.state.user.midGlobal; // 检测midGlobal
    }
    if (!(this.globalMenuList && this.globalMenuList.length > 0)) {
      await this.loadRouter();
    } else {
      this.menuList = this.globalMenuList;
    }
    this.showSideMenu = !this.$route.meta.hideLeftMenu
  },
  methods: {
    ...mapMutations("user", [
      "setToken",
      "setUserInfo",
      "setMerchantList",
      "setAuthRoutes",
      "setMenuList",
      "setCustomerJourneyAttr"
    ]),
    ...mapMutations("user", ["setAuthRoutes", "setMenuList", 'SETMIDGLOBAL']),
    toggleSide() {
      this.showSide = !this.showSide;
    },
    hasRoute(menu) {
      let flag = false;
      if (menu.router === this.$route.path) {
        flag = true;
      } else {
        if (menu.children) {
          menu.children.forEach((item) => {
            if (item.router === this.$route.path) {
              flag = true;
            } else {
              if (item.children) {
                item.children.forEach((subItem) => {
                  if (subItem.router === this.$route.path) {
                    flag = true;
                  }
                });
              }
            }
          });
        }
      }
      // 通过push 跳转 展开侧边栏
      if (flag) {
        if (menu.type == "URL") {
          this.sideMenu = [];
          this.showSideMenu = false;
          if (this.$route.path != menu.router) {
            this.$router.push(menu.router);
            sessionStorage.removeItem("side_menu_cache");
          }
        }
        if (menu.type == "NODE") {
          this.sideMenu = menu.children;
          this.showSideMenu = true;
          sessionStorage.setItem(
            "side_menu_cache",
            JSON.stringify(menu.children)
          );
        }
      }

      return flag;
    },

    onMenuRightClick(menu) {
      console.log(menu, 'menu')
      if (
        menu.type == "URL" &&
        !menu.router &&
        this.$route.name !== "expirePage"
      ) {
        this.$router.push({ name: "expirePage" })
      }

      if (menu.type == "URL" && menu.router && menu.router.startsWith("http")) {
        window.open(menu.router)
        return false
      }

      if (
        menu.type == "URL" &&
        menu.router
      ) {
        this.SETMIDGLOBAL(menu.mid)
        this.mid = menu.mid
        this.$router.push(menu.router)
        return false
      }

    },
    onMenuClick(menu) {
      if (menu.type == "URL") {
        this.sideMenu = [];
        this.showSideMenu = false;
        if (this.$route.path != menu.router) {
          this.$router.push(menu.router);
          sessionStorage.removeItem("side_menu_cache");
        }
      }
      if (menu.type == "NODE") {
        console.log("menu", menu.children);
        this.openSideMenu(menu.children);
      }
    },
    openSideMenu(menus) {
      this.sideMenu = menus;
      this.showSideMenu = true;
      this.openFirstMenu(menus[0]);
      sessionStorage.setItem("side_menu_cache", JSON.stringify(menus));
    },
    openFirstMenu(obj) {
      if (obj.type == "URL") {
        this.$router.push(obj.router);
        return;
      }

      if (obj.type == "NODE") {
        obj.children.forEach((item, index) => {
          if (index == 0 && item.router) {
            this.$router.push(item.router);
            return;
          }
        });
      }

      // for (let i = 0; i < list.length; i++) {
      //   const m = list[i];
      //   if (m.type == "NODE" && m.children) {
      //     const success = this.openFirstMenu(m.children);
      //     if (success) {
      //       break;
      //     }
      //   }
      //   if (m.type == "URL") {
      //     if (this.$route.path != m.router && m.router) {
      //       this.$router.push(m.router);
      //       return true;
      //     } else {
      //       return;
      //     }
      //   }
      // }
      // return false;
    },
    toUseInfo(item) {
      this.mid = '03010600000000000000'
      this.$router.push({ name: 'Personal' })
    },
    async exit() {

      let rs = await AuthApi.toExit(); // 退出登录
      sessionStorage.clear();
      this.setToken("");
      this.setUserInfo({});
      this.setMerchantList([])
      this.setMenuList([]);
      this.setAuthRoutes([]);

      this.SETMIDGLOBAL(null);
      localStorage.clear();
      // if (this.$store.state.user.isTestLogin) {
      //   this.$router.replace(
      //     '/login?redirect=' + encodeURIComponent(this.$router.currentRoute.fullPath)
      //   )
      // } else {
      window.location.replace(HomeLogin)
      // }

    },
    getLoadRouter(arr) {
      arr.forEach((item) => {
        this.authIds.push(item.mid);
        let children = item.children;
        if (children && children.length > 0) {
          this.getLoadRouter(children);
        }
      });
    },
    async loadRouter() {
      const rs = await CommonApi.loadRouter();
      if (rs.status == 200) {
        this.menuList = rs.payload.list;
        this.authIds = [];
        this.getLoadRouter(this.menuList); // 获取所有的权限id
        this.setAuthRoutes(this.authIds);
        this.setMenuList(this.menuList);
      } else {
        this.$message.error(rs.error);
      }
    },
    // 回到首页
    toDashboard() {
      if (this.$route.name !== "Dashboard") {
        this.$router.push({
          name: "Dashboard",
        });
      }
    },
    // 切换企业
    async changeMerchant(item) {

      if (!item.id) return this.$message.error('无企业id')

      let form = new FormData()
      form.append('changeMerchantId', item.id)

      const res = await personalApi.changeMerchant(form)

      if (res.status === 200) {

        let data = res.payload
        if (!data || !data.user) return this.$message.error('切换失败')
        this.$message.success('切换成功')

        let userInfo = JSON.parse(res.payload.user)
        let token = userInfo.token || ''

        this.setUserInfo(userInfo)
        this.setToken(token)

        sessionStorage.clear()
        this.setMenuList([])
        this.setAuthRoutes([])

        //  客户旅程模块：获取当前账号设置的属性集
        let result = await this.getLoginPropertyConfigType()

        if (result) {
          this.SETMIDGLOBAL(0)
          if (this.$route.name !== 'Dashboard') this.$router.replace({ name: "Dashboard" })
          this.reload()
        }

      } else {
        this.$message.error(res.error)
      }

    },
    // 客户旅程模块：获取当前账号设置的属性集
    async getLoginPropertyConfigType() {

      this.loading = true
      let attr = await CustomerJourneyApi.getLoginPropertyConfigType()
      this.loading = false

      if (attr.status === 200) {
        this.setCustomerJourneyAttr(attr.payload)
        return true
      } else {
        this.$message.error(attr.error)
        return false
      }
    }

  },
};
</script>

<style lang="scss" scoped>
.el-dialog__wrapper {
  /deep/ .el-dialog__body {
    max-height: 500px;
    overflow-y: auto;
  }
  /deep/ img {
    max-width: 100%;
  }
}
.home {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  outline: none;
  min-width: 1158px;

  .header {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px !important;
    display: flex;
    user-select: none;
    background: #1d2452;
    .platform-name {
      color: #fff;
      font-size: 12px;
      display: flex;
      align-items: center;
      flex-shrink: 0;
      .icon {
        width: 12px;
        height: 12px;
        margin: 0 6px 0 0;
      }
      img {
        width: 76px;
        margin-left: 8px;
      }
      // .corp-span {
      //   position: relative;
      //   padding-left: 15px;

      //   &::after {
      //     content: "";
      //     position: absolute;
      //     display: inline-block;
      //     left: 0;
      //     top: 3px;
      //     height: 12px;
      //     width: 1px;
      //     background: rgba($color: #fff, $alpha: 0.8);
      //   }
      // }
    }

    .logo {
      flex: 0 0 150px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      cursor: pointer;

      .iconfont {
        font-size: 30px;
        color: #1296db;
      }

      img {
        height: 42px;
      }
    }

    .menu {
      flex: 1 1 auto;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-left: 50px;

      .menu-item {
        position: relative;
        padding: 0 24px;
        cursor: pointer;
        height: 38px;
        line-height: 38px;
        font-size: 14px;
        color: rgba($color: #ffffff, $alpha: 0.85);
        transition: all 0.2s;

        &:hover {
          color: #fff;
        }
      }

      .selected {
        color: #fff;
        border-bottom: 2px solid #6178ff;
      }
    }

    .merchant-bar {
      padding: 5px;
      .el-dropdown-link {
        font-size: 12px;
        color: #fff;
        margin-left: 20px;
        position: relative;
        &::after {
          content: "";
          position: absolute;
          display: inline-block;
          left: -13px;
          top: 1px;
          height: 12px;
          width: 1px;
          background: rgba($color: #fff, $alpha: 0.8);
        }
      }
    }

    .toolbar {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      color: #fff;
      flex-shrink: 0;

      .corp-name {
        font-size: 14px;
        margin-right: 26px;
        font-weight: 400;
      }

      .el-badge {
        margin-right: 20px;
        font-size: 14px;
        cursor: pointer;
      }

      /deep/ .el-dropdown-link {
        color: #fff;
        cursor: pointer;
      }
    }
  }

  .body {
    position: relative;
    background: #f4f6f9;
    padding: 0;
    display: flex;

    .side-menu {
      overflow-y: auto;
      overflow-x: hidden;
      height: 100vh;
      width: 248px;
      flex: none;
      box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);
      background: #333c73;
      user-select: none;
      overflow-y: hidden;
      transition: width 400ms;
      padding-top: 64px;
      //box-sizing: border-box;
      padding-bottom: 16px;
      z-index: 100;
      &:hover {
        overflow-y: auto;
      }

      .menu-list {
        width: 248px;
        height: 100%;
        .menuItem {
          color: rgba(255, 255, 255, 0.7);
          font-size: 14px;
          margin-bottom: 10px;
          cursor: pointer;
          &-title {
            color: rgba(255, 255, 255, 0.9);
            height: 32px;
            margin-bottom: 10px;
            padding: 0 12px;
            line-height: 32px;
            .iconfont {
              margin-right: 5px;
              font-size: 12px;
            }

            &.active {
              color: #333;
              background: #fff;
            }
          }
          &-list {
            padding: 0 12px;
            &-item {
              width: 50%;
              height: 21px;
              line-height: 21px;
              margin-bottom: 12px;
              padding: 0 0 0 16px;
              .text {
                display: inline-block;
                border-radius: 5px;
                position: relative;
                padding: 0 4px;
                &.active {
                  color: #333;
                  background: #fff;
                }
                .tips {
                  position: absolute;
                  top: -14px;
                  right: -13px;
                  img {
                    width: 22px;
                  }
                }
              }
            }
          }
        }
      }

      .bottom-icon {
        position: absolute;
        border-top: 1px solid #4e5cb0;
        bottom: 10px;
        width: 100%;
        height: 40px;
        z-index: 99;
        background: #333c73;
      }

      .menu-name {
        transition: all 400ms;
      }
      .menu-name-hide {
        transform: rotate(90deg);
      }
    }
    .side-menu-hide {
      width: 82px;
    }

    .body-content {
      flex: 1;
      padding-top: 50px;
      height: 100vh;
      overflow: auto;
      transition: left 400ms;
      min-width: 970px;
    }
    .wide-content {
      left: 82px;
    }

    .body-content-full {
      //left: 0;
      padding-left: 0;
    }
  }
}
</style>
