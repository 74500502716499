import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

const moduleFiles = require.context('./modules', true, /\.js$/)
let modules = moduleFiles.keys().reduce((modules, route) => {
  const name = route.replace(/^\.\/(.*)\.\w+$/, '$1')
  modules[name] = moduleFiles(route).default
  return modules
}, {})

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {},
  modules,
  plugins: [createPersistedState({
    storage: window.localStorage,
    reducer(val) {
      return {
        user: val.user,
        welcome: val.welcome
      }
    }
  })]
})
