<template>
  <div class="switch" :style="{width:width+'px'}">
    <div v-if="swatchValue==activeValue" class="label-left">{{activeText}}</div>
    <el-switch
      v-model="swatchValue"
      :active-value="activeValue"
      :inactive-value="inactiveValue"
      :width="width" 
      :disabled="disabled"
      @change="$emit('input',swatchValue)">
    </el-switch>
    <div v-if="swatchValue==inactiveValue" class="label-right">{{inactiveText}}</div>
  </div>
  
</template>

<script>
  export default {
    props:{
      disabled: Boolean,
      activeText: String,
      inactiveText: String,
      // value:[String, Boolean, Number],
      value: {
        type: [String, Boolean, Number],
        default: ()=>{
          return 0
        },
      },
      activeValue:{
        type:[String, Number, Boolean],
        default:true
      },
      inactiveValue:{
        type:[String, Number, Boolean],
        default:false
      },
      width:{
        type:Number,
        default:54
      }
    },
    data() {
      return {
        swatchValue: 0,
        style: {
          width:'256px'
        }
      };
    },
    watch:{
      value: {
        handler(val) {
          this.swatchValue=val
        },
        immediate: true
      }
    },
  };
</script>

<style lang="scss" scoped>
  .switch{
    position: relative;
    line-height: 20px;
    display: inline-block;
    vertical-align: middle;
    .label-left,.label-right{
      position: absolute;
      font-size: 12px;
      color: #ffffff;
      z-index: 1;
      top: 0;
      
    }
    .label-left{
      left: 10px;
    }
    .label-right{
      right: 10px;
    }
    .el-switch{
      vertical-align: unset;
      user-select: unset;
    }
  }
</style>
