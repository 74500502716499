<template>
  <div class="jumpPrompt">
    <!-- header -->
    <div class="jp_head">
      <div class="logo-box">
        <img :src="darkLogo" alt="logo">
      </div>
    </div>
    <!-- container -->
    <div class="jp_container">
      <div class="con_box">
        <img :src="jump_img" alt="zs">
        <p class="text1">您的账号未安装掌站营销宝，赶紧去扫码安装吧</p>
        <p class="text2">您正在登录的企业未安装掌站营销宝，请重新选择企业登录或者安装应用</p>
        <p class="btn_no" @click="handleClickInstall">没有安装掌站营销宝，立刻安装</p>
        <p class="btn_rept" @click="handleClickScan">重新扫码</p>
      </div>
    </div>
    <!-- footer -->
    <div class="jp_footer">
      <p class="test_f">© 2009-2018 ecorescrm.com 版权所有ICP证: 浙B2-20170379-3</p>
    </div>
  </div>
</template>

<script>
import darkLogo from "@/assets/img/logo2.png"
import jump_img from "@/assets/img/jump_img.png"
import AuthApi from "@/api/authApi";

export default {
  name: 'JumpPrompt',
  data() {
    return {
      darkLogo,
      jump_img
    }
  },
  mounted() {

  },
  methods: {
    // 没有安装掌站
    handleClickInstall() {
      window.open(this.$route.query.url, '_blank');
    },
    // 重新扫码
    async handleClickScan() {
      let rs = await AuthApi.createWechatAuthUrl();
      if (rs.status === 200) {
        window.location.href = rs.payload;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.jumpPrompt {
  width: 100%;
  .jp_head {
    width: 100%;
    height: 64px;
    background: #FFFFFF;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position:absolute;
    top:0;
    .logo-box {
      width: 200px;
      height: 34px;
      margin-left: 120px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .jp_container {
    width: 100%;
    position:absolute;
    top: 64px;
    bottom: 50px;
    background: #F4F5FA;
    display: flex;
    justify-content: center;
    align-items: center;
    .con_box {
      width: 640px;
      height: 514px;
      background: #FFFFFF;
      box-shadow: 0px 12px 24px 11px rgba(97, 120, 255, 0.06);
      border-radius: 4px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      img {
        width: 200px;
        height: 200px;
        margin-bottom: 5px;
      }
      .text1 {
        width: 400px;
        height: 28px;
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #312F2F;
        line-height: 28px;
        margin-bottom: 8px;
      }
      .text2 {
        width: 496px;
        height: 22px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #312F2F;
        line-height: 22px;
        margin-bottom: 50px;
      }
      .btn_no {
        width: 344px;
        height: 48px;
        background: #6178FF;
        border-radius: 4px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 48px;
        text-align: center;
        margin-bottom: 24px;
        cursor: pointer;
        transition: all 0.2s;
        &:hover {
          opacity: 0.8;
        }
      }
      .btn_rept {
        width: 344px;
        height: 48px;
        background: #FFFFFF;
        border-radius: 4px;
        border: 1px solid #6178FF;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #6178FF;
        line-height: 48px;
        text-align: center;
        cursor: pointer;

      }
    }
  }
  .jp_footer {
    width: 100%;
    height: 50px;
    position:absolute;
    bottom: 0;
    background: #F4F5FA;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    .test_f {
      width: 488px;
      height: 22px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 22px;
    }
  }
}
</style>
