let mixin={
  data(){
    return{
      LinkPattern: /^$|(http|https):\/\/([\w.]+\/?)\S*/, // 可以为空的链接正则表达式
      PhonePattern: /^1[3456789]\d{9}$/ // 手机正则
    };
  },
  methods:{
     /**
     * 将data的值赋到params中
     * @param {Objct} params 被赋值
     * @param {Objct} data 原对象,用于给params赋值
     * @param {Function} callback 回调函数
     */
      $mergeData(params, data, callback) {
        let state = true
        for (const key in params) {
          state = true
          if (data[key] === undefined) continue
          if (typeof callback === "function") {
            state = callback(data[key], key, params)
          }
          // 默认保存data的值到params,替换原来params[key]值
          // 如果不保存data的值到params, callback回调函数返回false
          if (state !== false) {
            params[key] = data[key]
          }
        }
      },
    $showBtn(mid) {
      if(!mid) return false;
      // 权限码
      let authRoutes = this.$store.state.user.authRoutes;
      let idx = authRoutes.indexOf(mid);
      if(idx != -1) {
        return true
      }
      return false
    }
  }
};
export default mixin;