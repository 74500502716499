export default [
    {
      path: '/community',
      name: 'CommunityManage',
      meta: {
        title: '群发消息',
      },
      component: ()=>import('@/views/community/Index'),
      children: [
        {
          path: 'auto',
          name: 'Auto',
          meta: {
            title: '新客自动拉群',
            keepAlive: true,
          },
          component: () => import('@/views/community/auto/Index'),
        },
        {
          path: 'add',
          name: 'Add',
          meta: {
            title: '新建自动拉群',
          },
          component: () => import('@/views/community/auto/add'),
        },
        {
          path: 'new-group-detail',
          name: 'Detail',
          meta: {
            title: '新客拉群详情',
          },
          component: () => import('@/views/community/auto/detail'),
        },
        {
          path: 'old-tag-group',
          name: 'oldTagGroup',
          meta: {
            title: '老客标签建群',
            keepAlive: true,
          },
          component: () => import('@/views/community/oldTagGroup/Index'),
        },
        {
          path: 'new-tag-group',
          name: 'newTagGroup',
          meta: {
            title: '新建标签建群',
          },
          component: () => import('@/views/community/oldTagGroup/newTagGroup'),
        },
        {
          path: 'tag-group-detail',
          name: 'tagGroupDetail',
          meta: {
            title: '新建标签建群',
          },
          component: () => import('@/views/community/oldTagGroup/tagGroupDetail'),
        },
        {
          path: 'old-group-detail',
          name: 'oldGroupDetail',
          meta: {
            title: '老客标签建群详情',
          },
          component: () => import('@/views/community/oldTagGroup/oldTagGroupDetail'),
        },
        {
          path: 'group-sop',
          name: 'groupSop',
          meta: {
            title: '群SOP',
            keepAlive: true,
          },
          component: () => import('@/views/community/groupSOP/Index'),
        },
        {
          path: 'create-rule',
          name: 'createRule',
          meta: {
            title: '创建规则',
          },
          component: () => import('@/views/community/groupSOP/createRule'),
        },
        {
          path: 'rule-Detail',
          name: 'ruleDetail',
          meta: {
            title: '创建规则',
          },
          component: () => import('@/views/community/groupSOP/ruleDetail'),
        },
        {
          path: 'say-art',
          name: 'sayArt',
          meta: {
            title: '企业话术库',
          },
          component: () => import('@/views/community/sayArt/Index'),
        },
      ]
    },
  ]
