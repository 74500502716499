import axios from 'axios'

class AuthApi {
  /**
   * 生成微信登录链接、key
   */
  createWechatAuthUrl() {
    let url = '/api/auth/create/wechat/auth/url'
    return axios.get(url)
  }

    /**
   * 生成微信登录链接2、key
   * url 回调地址
   */
     newWechatAuthUrl(redirectUri) {
      let url = `/api/generalUser/login/create/wechat/auth/url?redirectUri=${redirectUri}`
      return axios.get(url)
    }

  /**
   * 登录校验
   * @param {string} code createWechatAuthUrl返回的key
   */
  checkLogin(code) {
    let url = `/api/auth/wechat/login/${code}`
    return axios.get(url)
  }

  /**
   * 退出登录
   * @param {string} 
   */
  toExit() {
    let url = `/api/auth/exit`
    return axios.get(url)
  }

  /**
   * 生成登录链接、key
   */
  createAuthUrl() {
    let url = '/api/auth/create/auth/url'
    return axios.get(url)
  }

  /**
   * 轮询扫码结果
   * @param {string} code createAuthUrl返回的key
   */
  checkAuth(code) {
    let url = `/api/auth/check/auth/${code}`
    return axios.get(url)
  }
}
export default new AuthApi()
