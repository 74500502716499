/*
 * @Author: liuxiang
 * @Date: 2021-04-06 16:18:35
 * @LastEditors: liuxiang
 * @LastEditTime: 2021-04-20 14:28:56
 * @Description: file content
 */
export default [
  {
    path: '/code',
    name: 'CodeManage',
    meta: {
      title: '引流码',
    },
    component: ()=>import('@/views/code/Index'),
    children: [
      {
        path: 'staff',
        name: 'CodeStaff',
        meta: {
          title: '员工活码',
          keepAlive: true,
        },
        component: () => import('@/views/code/staff/Index'),
      },
      {
        path: 'staff-add',
        name: 'CodeStaffAdd',
        meta: {
          title: '新建员工活码',
        },
        component: () => import('@/views/code/staff/StaffEdit'),
      },
      {
        path: 'staff-edit/:id',
        name: 'CodeStaffEdit',
        meta: {
          title: '编辑员工活码',
        },
        component: () => import('@/views/code/staff/StaffEdit'),
      },
      {
        path: 'staff-detail/:id',
        name: 'CodeStaffDetail',
        meta: {
          title: '员工活码详情',
        },
        component: () => import('@/views/code/staff/StaffDetail'),
      },
      {
        path: 'welcome',
        name: 'CodeWelcome',
        meta: {
          title: '欢迎语',
          keepAlive: true,
        },
        component: () => import('@/views/code/welcome/Index'),
      },
      {
        path: 'materialCenter',
        name: 'MaterialCenter',
        meta: {
          title: '素材中心',
        },
        component: () => import('@/views/materialCenter/Index'),
      },
      {
        path: 'first-create',
        name: 'firstCreate',
        meta: {
          title: '活码欢迎语创建',
        },
        component: () => import('@/views/code/welcome/FirstCreate'),
      },
      {
        path: 'second-create',
        name: 'secondCreate',
        meta: {
          title: '部门员工欢迎语创建',
        },
        component: () => import('@/views/code/welcome/SecondCreate'),
      },
      {
        path: 'third-create',
        name: 'thirdCreate',
        meta: {
          title: '客户群欢迎语创建',
        },
        component: () => import('@/views/code/welcome/ThirdCreate'),
      },
      {
        path: 'postersManagement',
        name: 'PostersManagement',
        meta: {
          title: '海报管理',
        },
        component: () => import('@/views/postersManagement/index'),
      }
    ],
  },
]
